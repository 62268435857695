import * as Sentry from '@sentry/react';
import { getEnvConfig } from 'getEnvConfig';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Loading } from '@zodinet-pos/core-ui';

Sentry.init({
    dsn: getEnvConfig.SENTRY_URL,
    enabled: getEnvConfig.SENTRY_ENABLE?.toLowerCase() === 'true',

    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                getEnvConfig.API_URL,
                getEnvConfig.COMMON_API_URL,
                getEnvConfig.PORTAL_URL,
            ],
        }),

        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    // <React.StrictMode>
    <React.Suspense fallback={<Loading />}>
        <App />
    </React.Suspense>,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
