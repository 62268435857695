import './styles.scss';

import { AppLogo } from '@enums/app-logos.enum';
import { IResourceComponentsProps, useGetIdentity } from '@refinedev/core';

import { SA_ROLE } from '../../constants/index.constant';
import { getEnvConfig } from '../../getEnvConfig';
import { IUser } from '../../interfaces/user';
import { FFoodDashboard } from '../ffood-dashboard';
import { SaRevenueIndex } from '../sa-revenue';
import { IntroduceIndex } from './introduce';

interface IDashboardOptions {
    name: string;
}
export const DashboardIndex: React.FC<
    IResourceComponentsProps<unknown, IDashboardOptions>
> = () => {
    const { data: useIdentity } = useGetIdentity<IUser.IUserDto>();
    const isSystemAdmin = useIdentity?.roles && useIdentity?.roles.indexOf(SA_ROLE) !== -1;
    const isFFood = getEnvConfig.MAIN_LOGO === AppLogo.FFOOD;

    return (
        <>
            {isSystemAdmin ? (
                <SaRevenueIndex />
            ) : (
                <>
                    <section className="header-container mb-6">
                        {isFFood ? <FFoodDashboard /> : <IntroduceIndex />}
                    </section>
                </>
            )}
        </>
    );
};
