import { POS_PLAN_ID } from '@zodinet-pos/core-ui';

export const PLAN_FEATURE_CONTENTS: Record<string, string[]> = {
    [POS_PLAN_ID.FREE]: [
        'Tích hợp tất cả ví điện tử',
        'Tích hợp thanh toán Online',
        'Thông báo đơn hàng từ Web order',
        'Quản lý trạng thái đơn Web order',
        'Không giới hạn số lượng đơn hàng',
        'Menu điện tử cho khách tự gọi món',
        'Số lượng máy trạm theo gói FNet đang sử dụng',
        'Kết nối 1 máy in',
        'Kết nối 1 thiết bị bán hàng',
        'Báo cáo bán hàng cơ bản',
        'Quản lý số lượng sản phẩm tồn',
    ],
    [POS_PLAN_ID.STANDARD]: [
        'Đấy đủ các tính năng của gói Cơ bản',
        'Web bán hàng Online',
        'Kết nối 4 máy in',
        'Không giới hạn số lượng thiết bị bán hàng (POS, Điện thoại, Tablet, PC)',
        'Sơ đồ phòng máy, máy trạm',
        'Quản lý topping',
        'Quản lý phân quyền nhân viên',
        'Quản lý đóng mở, báo cáo ca',
        'Theo dõi báo cáo hoạt động từ xa',
        'Thống kê tài chính, chỉ số kinh doanh chi tiết',
        'Xuất báo cáo, thống kê',
        'Màn hình hiển thị bar bếp',
        'Quản lý khuyến mãi, voucher',
        'Quản lý Xuất - Nhập kho',
        'Quản lý kho thành phẩm',
        'Hạng thẻ thành viên',
        'Quản lý thẻ trả trước (gift card)',
        'Định lượng nguyên vật liệu',
        'Quản lý nhập - xuất chuyển nhiều kho',
        'Hỗ trợ kỹ thuật giờ hành chính',
    ],
    [POS_PLAN_ID.PREMIUM]: [
        'Đầy đủ các tính năng của gói Chuyên nghiệp',
        'Không giới hạn số lượng máy in kết nối',
        'Không giới hạn số lượng thiết bị bán hàng (POS, Điện thoại, Tablet, PC)',
        'Quản lý chuỗi phòng game',
        'Hỗ trợ 24/7',
        'Cá nhân hoá theo yêu cầu',
        'Đã bao gồm gói Add on (Quản lý các loại hình kinh doanh khác: Bi-a, PS5, Café)',
    ],
    [POS_PLAN_ID.STANDARD_FNB]: ['Quản lý các loại hình kinh doanh khác: Bi-a, PS5, Café...'],
};
