import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { SERVICE_OTHER_OPTION_ID, WHITE_LABEL_SETTING_RESOURCE } from '@constants/index.constant';
import { IUser } from '@interfaces/user';
import { WLSetting } from '@interfaces/white-label-setting';
import { useSelect } from '@refinedev/antd';
import { useApiUrl, useCreate, useCustom, useGetIdentity, useNavigation } from '@refinedev/core';
import Response from '@responses/response';
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { TextEditor } from 'components/modules/editor';
import { SelectPartner } from 'components/modules/select-partner';
import { CurrencyType } from 'enums/currency-type.enum';
import { ProductFrequencyEnum } from 'enums/frequency.enum';
import { UserTypeEnum } from 'enums/user-type.enum';
import { keyBy } from 'lodash';
import { WLSettingFields } from 'pages/wl-setting/constants/white-label-setting-fields';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IPartner } from '../../interfaces/partner';
import { IService } from '../../interfaces/service';
import { formatPrice, formatPriceBaseOnCurrency } from '../../utils/resource';

export const OrderCreateIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    //state
    const [showPrice, setShowPrice] = useState<boolean>(false);

    const [form] = Form.useForm();
    const [serviceUpdated, setServiceUpdated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { mutate } = useCreate<any>();
    const { goBack, push } = useNavigation();

    const { data: user } = useGetIdentity<IUser.IUserIdentity>();
    const isTenant = user?.userType === UserTypeEnum.TENANT;

    const apiUrl = useApiUrl();

    const wlSettingResource = `${apiUrl}/${WHITE_LABEL_SETTING_RESOURCE}`;

    const { data: wlSetting } = useCustom<Response<WLSetting.IWLSetting>>({
        url: wlSettingResource,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    const SERVICES_URL = isTenant
        ? 'v1/services/active-services/order-on-behalf-partner'
        : 'v1/services/active-services';
    const { selectProps: serviceSelectProps, queryResult } = useSelect<IService>({
        resource: SERVICES_URL,
        optionLabel: 'name',
        optionValue: 'id',
        pagination: {
            mode: 'server',
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_services', {
                        ns: 'common',
                    }),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_services_failed', { ns: 'common' }),
                };
            }
        },
        queryOptions: {
            enabled: false,
        },
    });
    const _charge = wlSetting?.data?.data?.data?.[WLSettingFields.SERVICE_CHARGE_PERCENTAGE] || '0';

    const selectServiceOtherOptions = {
        label: t('quotes.fields.other_service.label'),
        value: SERVICE_OTHER_OPTION_ID,
    };

    useEffect(() => {
        if (user?.userType) {
            setShowPrice(user?.userType !== UserTypeEnum.CLIENT);
        }

        queryResult.refetch();
    }, [user]);

    const services: any = keyBy(queryResult.data?.data, 'id') || [];

    const onChangeService = (serviceId: string, index: number) => {
        const items = form.getFieldValue('items');
        items[index].productId = undefined;
        items[index].productName = null;

        items[index].unitPrice = null;
        items[index].qty = items[index].qty || 1;
        items[index].frequency = null;

        form.setFieldsValue({ items: items });
        setServiceUpdated(!serviceUpdated);
    };

    const onChangeProduct = (productId: string, index: number) => {
        const items = form.getFieldValue('items');
        const products = services[items[index].serviceId].products;
        const selectedProduct = products.find((p: any) => p.id === productId);

        items[index].productName = selectedProduct.name;
        items[index].unitPrice = selectedProduct.price;
        items[index].qty = 1;
        items[index].frequency = selectedProduct.frequency;

        form.setFieldsValue({ items: items });
        setServiceUpdated(!serviceUpdated);
    };

    const getSelectedPartner = (partner: IPartner.IPartnerInfo) => {
        form?.setFieldsValue({ partnerId: partner.id });
    };

    const clearPartner = () => {
        form.resetFields(['partnerId']);
    };

    const getTotalPrice = () => {
        const items = form?.getFieldValue('items');
        if (items) {
            let total = 0;
            for (const item of items) {
                let itemTotal = item?.qty * item?.unitPrice || 0;
                if (item?.margin) {
                    itemTotal = (itemTotal * item.margin) / 100 + itemTotal;
                }

                total += itemTotal;
            }

            return total;
        }
        return 0;
    };

    const onChangeStatus = () => {
        setServiceUpdated(!serviceUpdated);
    };

    const onFinish = (values: any) => {
        setIsLoading(true);
        for (const item of values.items) {
            const basePrice = Number(Number.parseFloat(item.unitPrice).toFixed(2));
            item.unitPrice = basePrice * 100;
        }

        const createUrl = isTenant ? 'v1/orders/on-behalf-partner' : 'v1/orders';
        mutate(
            {
                resource: createUrl,
                values: values,
                successNotification: {
                    message: t('quotes.message.create_successfully'),
                    type: 'success',
                },
            },
            {
                onError: (_) => {
                    setIsLoading(false);
                },
                onSuccess: (data) => {
                    if (data.data.data) {
                        if (data.data.data !== true) {
                            push(`/wl/invoices/show/${data.data.data}`);
                        } else {
                            goBack();
                        }
                    }
                },
            },
        );
    };

    const submitForm = () => {
        form.submit();
    };

    return (
        <>
            <section className="order-container">
                <div className="block-heading">
                    <span>{t('quotes.heading.createOrderTitle')}</span>
                    <div className="header-actions edit-order">
                        <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>
                        <Button type="primary" loading={isLoading} onClick={() => submitForm()}>
                            {t('quotes.actions.create')}
                        </Button>
                    </div>
                </div>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{ items: [{}] }}
                    onFinish={onFinish}
                >
                    {isTenant ? (
                        <>
                            <div className="section">
                                <div className="section-header">
                                    {t('quotes.heading.orderInformation')}
                                </div>
                                <Row gutter={20}>
                                    <Col xs={24} md={10}>
                                        <Form.Item
                                            label={t('quotes.fields.partner.label')}
                                            name="partnerId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('quotes.fields.partner.required'),
                                                },
                                            ]}
                                        >
                                            <SelectPartner
                                                getSelectedPartner={getSelectedPartner}
                                                clearPartner={clearPartner}
                                                isActivePartner={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div className="section">
                                <Row gutter={20}>
                                    <Col md={24}>
                                        <Form.Item
                                            label={t('quotes.fields.description.label')}
                                            name="description"
                                        >
                                            <TextEditor height="300px" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    ) : null}

                    <div className="section">
                        <div className="section-header">{t('quotes.heading.itemDetails')}</div>
                        <Row className="item-list">
                            <Col span={24}>
                                <Row
                                    className={` ${
                                        window.screen.width < 578 ? 'table-header-none' : ''
                                    } table-header`}
                                >
                                    <Col span={8} className="label-field-required">
                                        {t('quotes.fields.item.label')}
                                    </Col>
                                    {showPrice ? (
                                        <Col span={4}>{t('quotes.fields.unitPrice.label')}</Col>
                                    ) : null}
                                    {!showPrice ? (
                                        <Col span={6}>{t('quotes.fields.product.label')}</Col>
                                    ) : null}
                                    <Col span={4} className="label-field-required">
                                        {t('quotes.fields.qty.label')}
                                    </Col>
                                    <Col span={4}>{t('quotes.fields.frequency.label')}</Col>
                                    {showPrice ? (
                                        <Col span={2}>{t('quotes.fields.total.label')}</Col>
                                    ) : null}
                                </Row>
                                <Form.List name="items">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(
                                                ({ key, name, ...restField }, index: number) => {
                                                    const formItem =
                                                        form.getFieldValue('items')[index];
                                                    const type =
                                                        services[formItem?.serviceId]?.type || '';

                                                    let total =
                                                        formItem?.unitPrice * formItem?.qty || 0;
                                                    if (formItem?.margin) {
                                                        total += (total * formItem.margin) / 100;
                                                    }

                                                    const isSelectedOtherService =
                                                        formItem?.serviceId ===
                                                        selectServiceOtherOptions.value;

                                                    return (
                                                        <Row
                                                            className="list-product-item item-custom"
                                                            key={key}
                                                        >
                                                            <Col xs={24} sm={8}>
                                                                <>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'serviceId']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t(
                                                                                    'quotes.fields.item.required',
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        label={t(
                                                                            'quotes.fields.item.label',
                                                                        )}
                                                                    >
                                                                        <Select
                                                                            {...serviceSelectProps}
                                                                            options={
                                                                                isTenant ||
                                                                                user?.userType ===
                                                                                    UserTypeEnum.WHITELABEL
                                                                                    ? [
                                                                                          ...(serviceSelectProps.options?.filter(
                                                                                              (
                                                                                                  item,
                                                                                              ) =>
                                                                                                  item.value &&
                                                                                                  services[
                                                                                                      item
                                                                                                          .value
                                                                                                  ]
                                                                                                      ?.type ===
                                                                                                      UserTypeEnum.TENANT,
                                                                                          ) || []),
                                                                                          ...(isTenant
                                                                                              ? [
                                                                                                    selectServiceOtherOptions,
                                                                                                ]
                                                                                              : []),
                                                                                      ]
                                                                                    : [
                                                                                          ...(serviceSelectProps.options ||
                                                                                              []),
                                                                                      ]
                                                                            }
                                                                            showSearch={false}
                                                                            onChange={(item: any) =>
                                                                                onChangeService(
                                                                                    item,
                                                                                    index,
                                                                                )
                                                                            }
                                                                            placeholder={
                                                                                'Select service'
                                                                            }
                                                                        />
                                                                    </Form.Item>

                                                                    {/* Select product or enter product name */}
                                                                    {formItem?.serviceId &&
                                                                    showPrice ? (
                                                                        isSelectedOtherService ? (
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[
                                                                                    name,
                                                                                    'productName',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        required:
                                                                                            true,
                                                                                        message: t(
                                                                                            'quotes.fields.product.required',
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                                label={t(
                                                                                    'quotes.fields.product.label',
                                                                                )}
                                                                                className="product-custom"
                                                                            >
                                                                                <Input />
                                                                            </Form.Item>
                                                                        ) : (
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[
                                                                                    name,
                                                                                    'productId',
                                                                                ]}
                                                                                label={t(
                                                                                    'quotes.fields.product.label',
                                                                                )}
                                                                                rules={[
                                                                                    {
                                                                                        required:
                                                                                            true,
                                                                                        message: t(
                                                                                            'quotes.fields.product.required',
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                                className="product-custom"
                                                                            >
                                                                                <Select
                                                                                    showSearch={
                                                                                        false
                                                                                    }
                                                                                    placeholder={t(
                                                                                        'quotes.fields.product.placeholder',
                                                                                    )}
                                                                                    onChange={(
                                                                                        item,
                                                                                    ) =>
                                                                                        onChangeProduct(
                                                                                            item,
                                                                                            index,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {services[
                                                                                        formItem?.serviceId
                                                                                    ]?.products.map(
                                                                                        (
                                                                                            product: any,
                                                                                        ) => {
                                                                                            return (
                                                                                                <Select.Option
                                                                                                    key={
                                                                                                        product.id
                                                                                                    }
                                                                                                    value={
                                                                                                        product.id
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        product.name
                                                                                                    }
                                                                                                </Select.Option>
                                                                                            );
                                                                                        },
                                                                                    )}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        )
                                                                    ) : null}
                                                                </>
                                                            </Col>

                                                            {/* Show product selection as a separated column when not show price */}
                                                            {!showPrice ? (
                                                                <Col xs={24} sm={6}>
                                                                    {formItem?.serviceId ? (
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[
                                                                                name,
                                                                                'productId',
                                                                            ]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: t(
                                                                                        'quotes.fields.product.required',
                                                                                    ),
                                                                                },
                                                                            ]}
                                                                            label={t(
                                                                                'quotes.fields.product.label',
                                                                            )}
                                                                        >
                                                                            <Select
                                                                                showSearch={false}
                                                                                placeholder={t(
                                                                                    'quotes.fields.product.placeholder',
                                                                                )}
                                                                                onChange={(item) =>
                                                                                    onChangeProduct(
                                                                                        item,
                                                                                        index,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {services[
                                                                                    formItem?.serviceId
                                                                                ]?.products.map(
                                                                                    (
                                                                                        product: any,
                                                                                    ) => {
                                                                                        return (
                                                                                            <Select.Option
                                                                                                key={
                                                                                                    product.id
                                                                                                }
                                                                                                value={
                                                                                                    product.id
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    product.name
                                                                                                }
                                                                                            </Select.Option>
                                                                                        );
                                                                                    },
                                                                                )}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    ) : null}
                                                                </Col>
                                                            ) : null}

                                                            {showPrice ? (
                                                                <Col xs={24} sm={4}>
                                                                    <>
                                                                        {isSelectedOtherService ? (
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[
                                                                                    name,
                                                                                    'unitPrice',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        required:
                                                                                            true,
                                                                                        message: t(
                                                                                            'quotes.fields.unitPrice.required',
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                                label={t(
                                                                                    'quotes.fields.unitPrice.label',
                                                                                )}
                                                                            >
                                                                                <InputNumber
                                                                                    precision={2}
                                                                                    formatter={(
                                                                                        value,
                                                                                    ) =>
                                                                                        `${value}`.replace(
                                                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                                                            ',',
                                                                                        )
                                                                                    }
                                                                                    parser={(
                                                                                        value,
                                                                                    ) =>
                                                                                        value!.replace(
                                                                                            /\$\s?|(,*)-/g,
                                                                                            '',
                                                                                        )
                                                                                    }
                                                                                    onChange={
                                                                                        onChangeStatus
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        ) : (
                                                                            <span className="block mt-1 unit-price-custom invoice-price-custom">
                                                                                <span className="custom-label">
                                                                                    {t(
                                                                                        'quotes.fields.unitPrice.label',
                                                                                    )}
                                                                                </span>
                                                                                {formatPrice(
                                                                                    formItem?.unitPrice *
                                                                                        1,
                                                                                    CurrencyType.Vnd,
                                                                                )}
                                                                            </span>
                                                                        )}
                                                                        {type ===
                                                                        UserTypeEnum.WHITELABEL ? (
                                                                            <span className="charge">
                                                                                <b>
                                                                                    {t(
                                                                                        'quotes.fields.service_fee.label',
                                                                                    )}{' '}
                                                                                    :
                                                                                </b>
                                                                                {`${Number.parseInt(
                                                                                    _charge,
                                                                                )}%`}
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                </Col>
                                                            ) : null}

                                                            <Col xs={24} sm={4}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'qty']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t(
                                                                                'quotes.fields.qty.required',
                                                                            ),
                                                                        },
                                                                        {
                                                                            min: 1,
                                                                            type: 'number',
                                                                            message:
                                                                                'Quantity cannot be less than 1',
                                                                        },
                                                                    ]}
                                                                    label={t(
                                                                        'quotes.fields.qty.label',
                                                                    )}
                                                                >
                                                                    <InputNumber
                                                                        min={1}
                                                                        defaultValue={1}
                                                                        step={1}
                                                                        precision={0}
                                                                        onChange={onChangeStatus}
                                                                    />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col xs={24} sm={4}>
                                                                {isSelectedOtherService ? (
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'frequency']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t(
                                                                                    'quotes.fields.frequency.required',
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        label={t(
                                                                            'quotes.fields.frequency.label',
                                                                        )}
                                                                    >
                                                                        <Select
                                                                            getPopupContainer={(
                                                                                trigger,
                                                                            ) => trigger.parentNode}
                                                                            onChange={
                                                                                onChangeStatus
                                                                            }
                                                                        >
                                                                            <Select.Option
                                                                                value={
                                                                                    ProductFrequencyEnum.ONE_TIME_PAYMENT
                                                                                }
                                                                            >
                                                                                {t(
                                                                                    'services.frequency.' +
                                                                                        ProductFrequencyEnum.ONE_TIME_PAYMENT,
                                                                                    {
                                                                                        ns: 'common',
                                                                                    },
                                                                                )}
                                                                            </Select.Option>
                                                                            <Select.Option
                                                                                value={
                                                                                    ProductFrequencyEnum.PER_MONTH
                                                                                }
                                                                            >
                                                                                {t(
                                                                                    'services.frequency.' +
                                                                                        ProductFrequencyEnum.PER_MONTH,
                                                                                    {
                                                                                        ns: 'common',
                                                                                    },
                                                                                )}
                                                                            </Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                ) : (
                                                                    <span className="block mt-1 unit-price-custom invoice-price-custom">
                                                                        <span className="custom-label custom-label-order">
                                                                            {t(
                                                                                'quotes.fields.frequency.label',
                                                                            )}
                                                                        </span>
                                                                        {formItem?.frequency &&
                                                                            t(
                                                                                'services.frequency.' +
                                                                                    formItem?.frequency,
                                                                                { ns: 'common' },
                                                                            )}
                                                                    </span>
                                                                )}
                                                            </Col>

                                                            {showPrice ? (
                                                                <Col
                                                                    xs={24}
                                                                    sm={4}
                                                                    className="total-wrapper mt-1"
                                                                >
                                                                    <span className="total-price">
                                                                        {t(
                                                                            'quotes.fields.total.label',
                                                                        )}
                                                                    </span>
                                                                    <div className="total-container block mt-2">
                                                                        <span>
                                                                            {formatPriceBaseOnCurrency(
                                                                                total,
                                                                                CurrencyType.Vnd,
                                                                            )}
                                                                        </span>
                                                                        {index > 0 && (
                                                                            <img
                                                                                className="ml-auto"
                                                                                src="/images/icons/remove.svg"
                                                                                onClick={() => {
                                                                                    remove(name);
                                                                                    onChangeStatus();
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            ) : (
                                                                <Col span={2}>
                                                                    {index > 0 && (
                                                                        <img
                                                                            className="ml-auto"
                                                                            src="/images/icons/remove.svg"
                                                                            onClick={() =>
                                                                                remove(name)
                                                                            }
                                                                        />
                                                                    )}
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    );
                                                },
                                            )}

                                            <div className="btn-add-container">
                                                <Button
                                                    onClick={() => add()}
                                                    type="link"
                                                    icon={<PlusOutlined />}
                                                >
                                                    {t('quotes.actions.addItem')}
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                        {showPrice ? (
                            <div className="sum-total flex">
                                <span>{t('quotes.heading.totalPrice')}</span>
                                <span className="ml-auto">{formatPrice(getTotalPrice())}</span>
                            </div>
                        ) : null}
                    </div>
                </Form>
            </section>
        </>
    );
};
