import { Create, Edit } from '@refinedev/antd';
import { useTranslate } from '@refinedev/core';
import { Drawer, Grid } from 'antd';

import { IDrawerBase } from '../../interfaces/components/drawer';
import { TenantDetailForm } from './detail-form';

export const TenantDetail: React.FC<IDrawerBase> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    isEditMode,
}) => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const initialValues: any =
        !isEditMode && !formProps.initialValues?.id
            ? { isActive: true }
            : {
                  ...formProps.initialValues,
              };

    return (
        <>
            {drawerProps.open && (
                <Drawer
                    {...drawerProps}
                    width={breakpoint.sm ? '700px' : '100%'}
                    maskClosable={false}
                    zIndex={100}
                    className="drawer-form-container contact-drawer tenant-detail"
                    afterOpenChange={() => {
                        formProps.form?.resetFields();
                    }}
                >
                    {!isEditMode ? (
                        <Create
                            breadcrumb={null}
                            headerProps={{ extra: null, backIcon: null }}
                            saveButtonProps={saveButtonProps}
                        >
                            <TenantDetailForm formProps={formProps} mode={'add'} isCreate={true} />
                        </Create>
                    ) : (
                        <Edit
                            breadcrumb={null}
                            isLoading={!initialValues?.id ? true : false}
                            saveButtonProps={saveButtonProps}
                            headerProps={{ extra: null, backIcon: null }}
                        >
                            <TenantDetailForm formProps={formProps} mode={'edit'} />
                        </Edit>
                    )}
                </Drawer>
            )}
        </>
    );
};
