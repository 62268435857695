import './styles.scss';

import { useList, usePermissions } from '@refinedev/core';
import { Col, Empty, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationItem } from '../../components/marketplace/application-item';
import { CustomSearch } from '../../components/modules/custom-search';
import { IMarketplace } from '../../interfaces/marketplace';

const APPLICATION_RESOURCE_URL = 'v1/application';
export const MarketplaceIndex: React.FC = () => {
    const { t } = useTranslation(['common']);
    const [applications, setApplications] = useState<IMarketplace.IApplication[]>([]);
    const [filter, setFilter] = useState<string>('');
    const { data: userRights } = usePermissions<string[]>();

    const { data: listApplications, isLoading } = useList<IMarketplace.IApplication>({
        resource: `${APPLICATION_RESOURCE_URL}`,
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },
                {
                    field: 'includeUnpublish',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    });
    useEffect(() => {
        if (listApplications?.data) {
            setApplications(listApplications.data);
        }
    }, [listApplications]);

    return (
        <>
            <Spin spinning={isLoading}>
                <section className="application-list-container">
                    <>
                        <div className="list-header">
                            <CustomSearch
                                placeholder={`${t('marketplace.search_application')}`}
                                className={'search-item'}
                                onChange={(event: any) => setFilter(event.target.value)}
                                allowClear={true}
                            />
                        </div>
                        <div className="list-application-wrapper">
                            <Row gutter={[36, 36]}>
                                {applications?.map((application, index) => (
                                    <Col
                                        key={index}
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={12}
                                        xl={8}
                                        xxl={6}
                                        className="item-application"
                                    >
                                        <ApplicationItem
                                            application={application}
                                            permission={userRights || []}
                                        />
                                    </Col>
                                ))}
                            </Row>
                            {!applications.length && (
                                <div className="flex justify-center items-center">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                            )}
                        </div>
                    </>
                </section>
            </Spin>
        </>
    );
};
