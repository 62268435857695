import './styles.scss';

import { IOrder } from '@interfaces/order';
import { getDefaultSortOrder, mapAntdSorterToCrudSorting, ShowButton } from '@refinedev/antd';
import { useTable } from '@refinedev/core';
import { formatDate } from '@utils/date';
import { convertPrice } from '@utils/resource';
import { Table, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';

export const ContactOrderList: React.FC<{ contactId: string }> = ({ contactId }) => {
    const { t } = useTranslation(['quote']);
    const { setSorters, sorters, tableQueryResult } = useTable<IOrder.OrderList>({
        resource: 'v1/orders',
        sorters: {
            initial: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
        filters: {
            initial: [
                {
                    field: 'contactId',
                    operator: 'eq',
                    value: contactId,
                },
            ],
        },
        pagination: {
            mode: 'server',
            pageSize: 5,
        },
        syncWithLocation: true,
    });

    const onChangeTable = (
        pagination: TablePaginationConfig,
        tableFilters: Record<
            string,
            (string | number | boolean) | (string | number | boolean)[] | null
        >,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    return (
        <div className="list-content table-wrapper contact-orders-container">
            <Table
                dataSource={tableQueryResult.data?.data}
                loading={tableQueryResult.isFetching}
                onChange={onChangeTable}
                pagination={false}
                rowKey="id"
                tableLayout="auto"
                scroll={{ x: 'max-content' }}
                className="order-table"
            >
                <Table.Column
                    sorter
                    defaultSortOrder={getDefaultSortOrder('incrementId', sorters)}
                    title={<>{t('quotes.table.number')}</>}
                    dataIndex="incrementId"
                    key="incrementId"
                    width={80}
                    render={(text, _, index) => <p className="table-tbody-text">{text}</p>}
                />

                <Table.Column
                    sorter
                    defaultSortOrder={getDefaultSortOrder('status', sorters)}
                    title={<>{t('quotes.table.status')}</>}
                    dataIndex="status"
                    key="status"
                    width={120}
                    render={(text, _, index) => (
                        <p className={`table-tbody-text status-${text}`}>
                            {t('quotes.status.' + text)}
                        </p>
                    )}
                />
                <Table.Column
                    sorter
                    defaultSortOrder={getDefaultSortOrder('amount', sorters)}
                    title={<>{t('quotes.table.amount')}</>}
                    dataIndex="amount"
                    key="amount"
                    width={120}
                    render={(text, item: IOrder.OrderList, index) => (
                        <p className="table-tbody-text">{convertPrice(text)}</p>
                    )}
                />
                <Table.Column
                    title={<>{t('quotes.table.frequency')}</>}
                    dataIndex="frequencies"
                    key="frequencies"
                    width={200}
                    render={(frequencies, _, index) => (
                        <p className="table-tbody-text">
                            {frequencies.map((f: string) => t('quotes.frequency.' + f)).join(', ')}
                        </p>
                    )}
                />
                <Table.Column
                    sorter
                    width={200}
                    defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
                    title={<>{t('quotes.table.date')}</>}
                    dataIndex="createdAt"
                    key="createdAt"
                    render={(text, _, index) => (
                        <p className="table-tbody-text">{formatDate(text)}</p>
                    )}
                />
                <Table.Column
                    dataIndex="id"
                    key="action"
                    fixed="right"
                    render={(id, record: IOrder.OrderList, index) => (
                        <div
                            className="flex justify-between items-center"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <ShowButton
                                resource={'wl_order'}
                                recordItemId={id}
                                style={{ border: 'none' }}
                            />
                        </div>
                    )}
                />
            </Table>
        </div>
    );
};
