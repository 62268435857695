import './styles.scss';

import { AntDesignOutlined, CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { ITenant } from '@interfaces/tenant';
import { getDefaultSortOrder, mapAntdSorterToCrudSorting, useDrawerForm } from '@refinedev/antd';
import { useApiUrl, useCustomMutation, useTable } from '@refinedev/core';
import {
    Avatar,
    Button,
    Dropdown,
    Menu,
    notification,
    Popconfirm,
    Table,
    TablePaginationConfig,
    Tooltip,
} from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { LoginIcon } from 'components/layout/icons/login';
import { PINConfirm } from 'components/modules/pin-confirm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '../../components/modules/chip';
import { CustomSearch } from '../../components/modules/custom-search';
import { CustomPagination } from '../../components/modules/pagination';
import { TenantDetail } from '../../components/tenant/detail';
import { DATE_FORMAT_MINUTE, PAGE_SIZE } from '../../constants/index.constant';
import { TenantStatusEnum } from '../../enums/tenant-status.enum';
import { TenantTypeEnum } from '../../enums/tenant-type.enum';
import { UserStatusEnum } from '../../enums/user-status.enum';
import { formatDate, getPublicMediaUrl } from '../../utils/resource';

export const TenantIndex: React.FC = () => {
    const { t } = useTranslation(['partner', 'common']);
    const { mutate } = useCustomMutation();
    const apiUrl = useApiUrl();

    const [isLoginOnBehalf, setIsLoginOnBehalf] = useState(false);
    const [loginOnBehalfId, setLoginOnBehalfId] = useState<string>('');

    const {
        setFilters,
        tableQueryResult,
        current,
        setCurrent,
        pageSize,
        setPageSize,
        sorters,
        setSorters,
    } = useTable<ITenant.ITenantInfor>({
        resource: 'v1/tenant',
        sorters: {
            initial: [
                {
                    field: 'name',
                    order: 'asc',
                },
            ],
        },
        filters: {
            initial: [
                {
                    field: 'type',
                    operator: 'eq',
                    value: TenantTypeEnum.TENANT,
                },
            ],
        },
        pagination: {
            mode: 'server',
            pageSize: PAGE_SIZE,
        },
    });

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<ITenant.ITenantInfor>({
        action: 'create',
        resource: 'v1/tenant',
        successNotification: { message: 'Successfully created', type: 'success' },
        redirect: false,
        onMutationSuccess: (data) => {
            createFormProps.form.resetFields();
        },
        onMutationError: (error) => {
            notification.error({
                message: error?.message
                    ? t(`settings.${error?.response?.data?.error}`, { ns: 'common' })
                    : t('users.create_user_failed', { ns: 'common' }),
                type: 'error',
            });
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<ITenant.ITenantInfor>({
        action: 'edit',
        resource: 'v1/tenant',
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
    });

    const menu = (id: string, status: TenantStatusEnum, record: ITenant.ITenantInfor) => (
        <Menu mode="vertical">
            {(!record.ownerStatus || record.ownerStatus === UserStatusEnum.PENDING) && (
                <Menu.Item
                    key="0"
                    icon={
                        <img
                            src="/images/icons/send.svg"
                            alt="edit"
                            className="cursor-pointer mr-3"
                        />
                    }
                >
                    <Popconfirm
                        title={t('partners.messages.resend_invitation')}
                        placement="rightBottom"
                        onConfirm={() => {
                            handleResendInvitation(record.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        {t('partners.invite')}
                    </Popconfirm>
                </Menu.Item>
            )}

            <Menu.Item
                key="1"
                icon={<img src="/images/icons/edit.svg" alt="edit" className="cursor-pointer" />}
                onClick={() => {
                    editShow(id);
                }}
            >
                {t('actions.edit', { ns: 'common' })}
            </Menu.Item>
            {status != TenantStatusEnum.PENDING && (
                <>
                    <Menu.Item
                        key="2"
                        icon={
                            status === TenantStatusEnum.ACTIVE ? (
                                <CloseOutlined />
                            ) : (
                                <CheckOutlined />
                            )
                        }
                        onClick={() => {
                            const newStatus =
                                status == TenantStatusEnum.ACTIVE
                                    ? TenantStatusEnum.DEACTIVATED
                                    : TenantStatusEnum.ACTIVE;
                            handleStatus(id, newStatus);
                        }}
                    >
                        {t(
                            `actions.${
                                status == TenantStatusEnum.ACTIVE ? 'deactivate' : 'activate'
                            }`,
                            {
                                ns: 'common',
                            },
                        )}
                    </Menu.Item>

                    {status === TenantStatusEnum.ACTIVE ? (
                        <Menu.Item
                            key="3"
                            icon={
                                <div className="cursor-pointer">
                                    <LoginIcon />
                                </div>
                            }
                            onClick={() => {
                                setLoginOnBehalfId(id);
                                setIsLoginOnBehalf(true);
                            }}
                        >
                            {t('actions.login_on_behalf', { ns: 'common' })}
                        </Menu.Item>
                    ) : null}
                </>
            )}
        </Menu>
    );

    const renderStatus = (code: TenantStatusEnum) => {
        let label = '';
        let type = '';
        switch (code) {
            case TenantStatusEnum.PENDING:
                label = t('partners.status.pending', { ns: 'partner' });
                type = 'warning';
                break;
            case TenantStatusEnum.ACTIVE:
                label = t('partners.status.active', { ns: 'partner' });
                type = 'success';
                break;
            case TenantStatusEnum.DEACTIVATED:
                label = t('partners.status.deactivated', { ns: 'partner' });
                type = 'disable';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    const handleResendInvitation = (id: string) => {
        const url = `${apiUrl}/v1/tenant/invitation/${id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: {},
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, _, __) => {
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('partners.messages.send_invitation_success'),
                        });
                    } else {
                        notification.error({
                            className: 'error-notification',
                            message: '',
                            description: t('partners.errors.send_invitation_failed'),
                        });
                    }
                },
            },
        );
    };

    const handleStatus = (id: string, status: TenantStatusEnum) => {
        const url = `${apiUrl}/v1/tenant/${status}/${id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: {},
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, _, __) => {
                    if (data && data.data?.isSuccess) {
                        notification.success({
                            className: 'success-notification',
                            message: '',
                            description: t('notifications.editSuccess', {
                                ns: 'common',
                            }).replace('{{resource}}', 'status'),
                        });
                        tableQueryResult.refetch();
                    } else {
                        notification.error({
                            className: 'error-notification',
                            message: '',
                            description: t('notifications.editError', {
                                ns: 'common',
                            })
                                .replace('{{resource}}', 'status')
                                .replace('{{statusCode}}', 'tenant.update_failed'),
                        });
                    }
                },
            },
        );
    };

    const onSearchTenant = (event: any) => {
        setCurrent(1);
        setFilters([
            {
                field: 'filter',
                operator: 'eq',
                value: event?.target?.value,
            },
        ]);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        tableFilters: Record<
            string,
            (string | number | boolean) | (string | number | boolean)[] | null
        >,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const handleVerifyPinSuccessfully = (data: ITenant.ILoginOnBehalf) => {
        if (data) {
            window.open(
                `${window.location.protocol}//${data.tenantDomain}/login-on-behalf?token=${data.loginPayload?.token}`,
                '_self',
            );
        }
    };

    return (
        <>
            <TenantDetail
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                isEditMode={false}
            />
            <TenantDetail
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
                isEditMode={true}
            />
            <section className="partner-list-container">
                <div className="list-header">
                    <CustomSearch
                        placeholder="Search Tenants"
                        className={'search-item'}
                        onChange={(event: any) => onSearchTenant(event)}
                        allowClear={true}
                    />
                    <Button className="btn-add-new" type="primary" onClick={() => createShow()}>
                        <PlusOutlined />
                        {t('add_new', { ns: 'common' })}
                    </Button>
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult?.data?.data}
                            pagination={false}
                            rowKey="id"
                            onChange={onChangeTable}
                            scroll={{ y: '100%', x: '800px' }}
                        >
                            <Table.Column
                                title={<>{t('partners.name')}</>}
                                dataIndex="name"
                                key="name"
                                sorter
                                defaultSortOrder={getDefaultSortOrder('name', sorters)}
                                render={(row, record: ITenant.ITenantInfor) => (
                                    <div className="flex justify-between items-center text-ellipsis overflow-hidden">
                                        <div className="flex justify-start items-center">
                                            <div className="w-10 h-10">
                                                <Avatar
                                                    size={40}
                                                    src={
                                                        record.faviconUrl
                                                            ? getPublicMediaUrl(record.faviconUrl)
                                                            : null
                                                    }
                                                    icon={<AntDesignOutlined />}
                                                />
                                            </div>

                                            <p className="table-tbody-text ml-2 item-name pd-0 w-auto">
                                                <Tooltip title={row}>{row}</Tooltip>
                                            </p>
                                        </div>
                                    </div>
                                )}
                                width={300}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('partners.fields.email.label', { ns: 'partner' })}</>}
                                dataIndex="email"
                                key="email"
                                render={(text, _) => <p className="table-tbody-text">{text}</p>}
                                width={250}
                            />
                            <Table.Column
                                sorter
                                title={
                                    <>{t('partners.fields.phoneNumber.label', { ns: 'partner' })}</>
                                }
                                dataIndex="phone"
                                key="phone"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{text || '--'}</p>
                                )}
                                width={150}
                            />
                            <Table.Column
                                title={
                                    <>{t('partners.fields.planName.label', { ns: 'partner' })}</>
                                }
                                dataIndex="planName"
                                key="planName"
                                render={(text, _) => (
                                    <p className="table-tbody-text">
                                        {text
                                            ? t(`plan_form.plan_type.${text.toLowerCase()}`, {
                                                  ns: 'common',
                                              })
                                            : '--'}
                                    </p>
                                )}
                                width={150}
                            />
                            <Table.Column
                                title={
                                    <>{t('partners.fields.qtyTable.label', { ns: 'partner' })}</>
                                }
                                dataIndex="qtyTable"
                                key="qtyTable"
                                render={(text, _) => (
                                    <p className="table-tbody-text">{text || '--'}</p>
                                )}
                                width={150}
                            />
                            <Table.Column
                                sorter
                                title={<>{t('partners.fields.status.label', { ns: 'partner' })}</>}
                                dataIndex="status"
                                key="status"
                                render={(text, _) => renderStatus(text)}
                                width={150}
                            />

                            <Table.Column
                                sorter
                                title={<>{t('partners.created_at', { ns: 'partner' })}</>}
                                dataIndex="createdAt"
                                key="createdAt"
                                render={(text, _) => (
                                    <p className="table-tbody-text">
                                        {formatDate(text, DATE_FORMAT_MINUTE)}
                                    </p>
                                )}
                                width={100}
                            />
                            <Table.Column
                                dataIndex="id"
                                key="action"
                                render={(id, record: ITenant.ITenantInfor) => (
                                    <div className="flex justify-end items-center">
                                        <Dropdown
                                            overlay={menu(record.id, record.status, record)}
                                            placement="bottomRight"
                                            arrow
                                            overlayClassName="contact-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <img
                                                src="/images/icons/dots-vertical.svg"
                                                alt="more"
                                                className="cursor-pointer"
                                            />
                                        </Dropdown>
                                    </div>
                                )}
                                width={100}
                            />
                        </Table>
                    </div>
                </div>

                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
            {isLoginOnBehalf ? (
                <PINConfirm
                    title={t('pin.title', { ns: 'common' })}
                    visible={isLoginOnBehalf}
                    confirmData={{
                        tenantId: loginOnBehalfId,
                    }}
                    setVisible={setIsLoginOnBehalf}
                    onClose={(data: ITenant.ILoginOnBehalf | null) => {
                        if (!data) {
                            return;
                        }
                        handleVerifyPinSuccessfully(data);
                    }}
                />
            ) : null}
        </>
    );
};
