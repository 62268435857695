import { RightCircleOutlined } from '@ant-design/icons';
import {
    HttpError,
    useApiUrl,
    useCustom,
    useCustomMutation,
    useGetIdentity,
} from '@refinedev/core';
import { POS_PLAN_ID } from '@zodinet-pos/core-ui';
import { Button, Col, notification, Progress, Row, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    MESSAGE_CONTACT_COLLABORATOR_TO_ADD_ON,
    MESSAGE_CONTACT_COLLABORATOR_TO_RENEW,
    MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE,
    TIMEZONE_DEFAULT,
} from '../../constants/index.constant';
import Response from '../../data-access/responses/response';
import { CurrencyType } from '../../enums/currency-type.enum';
import { SubscriptionOrderTypeEnum } from '../../enums/subscription-order-type.enum';
import { NApplication } from '../../interfaces/application';
import { NRegistration } from '../../interfaces/registration';
import { NSubscriptionOrder } from '../../interfaces/subscription-order';
import { IUser } from '../../interfaces/user';
import { IntroduceIndex } from '../../pages/dashboard/introduce';
import { NotificationPaymentModal } from '../../pages/extra-application-plan/notification-payment-modal';
import { NotificationInformationModal } from '../../pages/extra-application-plan/notification-payment-modal/notification-information';
import { QRModal } from '../../pages/extra-application-plan/qr-modal';
import { dayjsDateDiff, getLocalTime } from '../../utils/date';
import { formatPriceBaseOnCurrency } from '../../utils/resource';
import { SubscriptionOrder } from '../subscription-order';
import { AddOnPlanModal } from './add-on-plan-modal';
import { UpgradeLicenseModal } from './upgrade-license-modal';
import { UpgradeModal } from './upgrade-modal';

const LIMIT_ORDER_QTY = 60;
const LIMIT_PRINTER_QTY = 4;
const LIMIT_CONNECT_DEVICE_QTY = 4;

export type IForm = { quantity?: number; referenceCode: string; extraAppPlanId?: string };

export const SubscriptionPlan: React.FC = () => {
    const { t, i18n } = useTranslation('common');
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();
    const apiUrl = useApiUrl();

    const [registration, setRegistration] = useState<NRegistration.IRegistrationForDashboard>();
    const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [qrUrl, setQrUrl] = useState<string>();
    const [isOpenQrModal, setIsOpenQrModal] = useState<boolean>(false);
    const [typeModal, setTypeModal] = useState<SubscriptionOrderTypeEnum>();
    const [subTitle, setSubTitle] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [isRefetch, setIsRefetch] = useState<boolean>(false);
    const [subscriptionOrderId, setSubscriptionOrderId] = useState<string>();
    const [totalPayment, setTotalPayment] = useState<number>(0);

    const isFreePlan = registration?.extraAppPlanId === POS_PLAN_ID.FREE;
    const isTenantOwner = userIdentity?.isTenantOwner;

    const {
        data,
        refetch: refetchDetail,
        isLoading: detailLoading,
    } = useCustom<Response<NRegistration.IRegistrationForDashboard>, HttpError>({
        url: `${apiUrl}/v1/registration/detail`,
        method: 'get',
        config: {},
        queryOptions: {
            enabled: isTenantOwner,
        },
    });

    const { mutate } = useCustomMutation<Response<NApplication.IPlanResponse>>();

    useEffect(() => {
        if (data?.data?.data) {
            setRegistration(data.data.data);
        }
    }, [data]);

    const handlePayment = (values: IForm, totalPayment: number) => {
        const _values: IForm & { timezone: string } = { ...values, timezone: TIMEZONE_DEFAULT };

        setIsLoading(true);

        let action = null;

        switch (typeModal) {
            case SubscriptionOrderTypeEnum.RENEW_LICENSE:
                action = 'renew-license';
                break;
            case SubscriptionOrderTypeEnum.UPGRADE_TABLE:
                action = 'upgrade-table';
                break;
            case SubscriptionOrderTypeEnum.UPGRADE_LICENSE: {
                action = 'upgrade-license';
                _values.extraAppPlanId = values?.extraAppPlanId;
                break;
            }
            case SubscriptionOrderTypeEnum.ADD_ON_PLAN: {
                action = 'add-on-plan';
                _values.extraAppPlanId = POS_PLAN_ID.STANDARD_FNB;
                break;
            }
        }

        mutate(
            {
                url: `${apiUrl}/v1/registration/${action}/${registration?.id}`,
                method: 'put',
                values: { ..._values },
                config: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                    },
                },

                errorNotification: (error) => {
                    setIsLoading(false);

                    const code = error?.response?.data?.error;

                    if (i18n.exists(`plan_form.errors.${code}`)) {
                        return {
                            message: t(`plan_form.errors.${code}`, { ns: 'common' }),
                            type: 'error',
                        };
                    } else {
                        return { message: code, type: 'error' };
                    }
                },
            },
            {
                onSuccess: (data) => {
                    const response = data.data?.data;

                    if (data.data?.isSuccess) {
                        setIsOpenUpgradeModal(false);

                        switch (response.qrUrl) {
                            case MESSAGE_CONTACT_COLLABORATOR_TO_UPGRADE:
                            case MESSAGE_CONTACT_COLLABORATOR_TO_RENEW:
                            case MESSAGE_CONTACT_COLLABORATOR_TO_ADD_ON: {
                                setTitle(t(`plan_form.actions.${typeModal}`));
                                setSubTitle(`notifications.${response.qrUrl}`);
                                setIsOpenQrModal(false);
                                break;
                            }
                            default: {
                                setIsOpenQrModal(true);
                                setTotalPayment(totalPayment);
                                setQrUrl(response.qrUrl);
                                setSubscriptionOrderId(response.subscriptionOrderId);
                                setTitle(t(`plan_form.success.${typeModal}_success`));
                            }
                        }
                    } else {
                        notification.error({
                            message: t(`plan_form.errors.upgrade_table_error`),
                        });
                    }
                    setIsLoading(false);
                },
            },
        );
    };

    const handlePaymentSubscriptionOrder = (subscriptionOrder: NSubscriptionOrder.IInfo) => {
        setIsLoading(true);

        const url = `${apiUrl}/v1/subscription-orders/payment/${subscriptionOrder.id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: { timezone: TIMEZONE_DEFAULT },
                errorNotification: false,
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    const code = error?.response?.data?.error;

                    if (i18n.exists(`plan_form.errors.${code}`)) {
                        notification.error({
                            message: t(`plan_form.errors.${code}`, { ns: 'common' }),
                        });
                    } else {
                        notification.error({
                            message: code,
                        });
                    }

                    console.error(error);
                    setIsLoading(false);
                },
                onSuccess: (data) => {
                    const response = data.data?.data;

                    if (data.data?.isSuccess) {
                        setTitle(t(`plan_form.success.${subscriptionOrder.type}_success`));
                        setTypeModal(subscriptionOrder.type);
                        setIsOpenQrModal(true);
                        setQrUrl(response.qrUrl);
                    } else {
                        notification.error({
                            message: t(`plan_form.errors.upgrade_table_error`),
                        });
                    }
                    setIsLoading(false);
                },
            },
        );
    };

    const remainingMonths: number = useMemo(() => {
        if (!registration) return 0;

        return dayjsDateDiff(
            dayjs(registration.expiredDate),
            dayjs(),
            registration.isTrial ? registration.frequency : 'M',
        );
    }, [registration]);

    const paymentFormula = ({
        expiredTime,
        price,
        qtyTable,
        fnbPrice = 0,
        isFnb,
    }: {
        price: number;
        expiredTime: number;
        qtyTable: number;
        fnbPrice?: number;
        isFnb?: boolean;
    }) => {
        return isFnb
            ? price * expiredTime
            : price * expiredTime * qtyTable + fnbPrice * expiredTime;
    };

    const handleNotiResetState = () => {
        setIsOpenUpgradeModal(false);
        setTypeModal(undefined);
        refetchDetail();
        setQrUrl(undefined);
        setSubTitle(undefined);
        setTitle(undefined);
        handleRefetchSubscriptionOrder();
        setSubscriptionOrderId(undefined);
    };

    const handleCloseModal = () => {
        setIsOpenUpgradeModal(false);
        setTypeModal(undefined);
        handleRefetchSubscriptionOrder();
    };

    const handleRefetchSubscriptionOrder = () => {
        setIsRefetch(true);
    };

    return (
        <>
            {!isTenantOwner || (!isLoading && !detailLoading && !registration) ? (
                <>
                    <IntroduceIndex />
                </>
            ) : (
                <Spin spinning={isLoading}>
                    {registration ? (
                        <>
                            <section className="plan-detail mb-10">
                                <Row gutter={[0, 48]} className="w-full justify-between">
                                    <Col
                                        xs={24}
                                        lg={24}
                                        xl={12}
                                        className="rounded-lg shadow-lg p-6 lg:mb-0 mb-4 flex flex-col justify-between"
                                    >
                                        <div>
                                            <section className="flex justify-between items-start lg:flex-row flex-col">
                                                <h2 className="text-2xl">
                                                    {t(`plan_form.fields.service_package`)}
                                                </h2>
                                                <div className="flex flex-col justify-between">
                                                    <p className={'text-4xl font-bold'}>
                                                        <span>
                                                            {formatPriceBaseOnCurrency(
                                                                registration.planPrice,
                                                                CurrencyType.Vnd,
                                                            )}{' '}
                                                        </span>
                                                        <span className="text-xl font-normal text-slate-400">
                                                            /{t('plan_form.pc_month')}
                                                        </span>
                                                    </p>
                                                </div>
                                            </section>

                                            <Tag
                                                bordered={false}
                                                className="px-3 py-2 lg:mt-1 mt-3 mb-6"
                                            >
                                                <span>
                                                    {t(
                                                        `plan_form.plan_type.${registration.extraAppPlanName.toLowerCase()}`,
                                                    ).toUpperCase()}
                                                </span>
                                                {registration.isTrial ? (
                                                    <span className="text-slate-400">
                                                        {' '}
                                                        ({t('plan_form.trial')})
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                            </Tag>

                                            <section className="grid lg:grid-cols-3 grid-cols-1 gap-4 mt-4">
                                                <div className="flex items-center justify-between flex-col lg:border-r lg:border-b-0 lg:p-0 border-b pb-3">
                                                    <p className="text-slate-400 mb-2">
                                                        {t('plan_form.member_since')}{' '}
                                                    </p>
                                                    <p className="font-bold">
                                                        {dayjs(getLocalTime(registration.startDate))
                                                            .locale(`${i18n.language}`)
                                                            .format('ll')}
                                                    </p>
                                                </div>

                                                <div className="flex items-center justify-between flex-col lg:border-r lg:border-b-0 lg:p-0 border-b pb-3">
                                                    <p className="text-slate-400 mb-2">
                                                        {t('plan_form.next_payment')}
                                                    </p>
                                                    <p className="font-bold">
                                                        {dayjs(
                                                            getLocalTime(registration.expiredDate),
                                                        )
                                                            .locale(`${i18n.language}`)
                                                            .format('ll')}
                                                    </p>
                                                </div>

                                                <div className="flex items-center justify-between flex-col lg:border-none lg:p-0 border-b pb-3">
                                                    <p className="text-slate-400 mb-2">
                                                        {t('plan_form.remaining_time', {
                                                            frequency: t(
                                                                `subscription_order.frequency_type.${
                                                                    registration.isTrial
                                                                        ? registration.frequency
                                                                        : 'month'
                                                                }`,
                                                            ),
                                                        })}
                                                    </p>
                                                    <p className="font-bold">{remainingMonths}</p>
                                                </div>
                                            </section>
                                        </div>

                                        <section className="grid lg:grid-cols-2 lg:gap-4 grid-cols-1 mt-10">
                                            {!isFreePlan ? (
                                                <Button
                                                    type="default"
                                                    icon={<RightCircleOutlined />}
                                                    className="w-full flex justify-center"
                                                    onClick={() => {
                                                        setTypeModal(
                                                            SubscriptionOrderTypeEnum.RENEW_LICENSE,
                                                        );
                                                        setIsOpenUpgradeModal(true);
                                                    }}
                                                >
                                                    {t('plan_form.actions.renew_license')}
                                                </Button>
                                            ) : (
                                                <></>
                                            )}

                                            {!registration.isTrial &&
                                            registration.extraAppPlanId !== POS_PLAN_ID.PREMIUM ? (
                                                <Button
                                                    type="primary"
                                                    icon={<RightCircleOutlined />}
                                                    className="w-full flex justify-center lg:mt-0 mt-4"
                                                    onClick={() => {
                                                        setTypeModal(
                                                            SubscriptionOrderTypeEnum.UPGRADE_LICENSE,
                                                        );
                                                        setIsOpenUpgradeModal(true);
                                                    }}
                                                >
                                                    {t('plan_form.actions.upgrade_license')}
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                        </section>

                                        <section className="grid lg:grid-cols-1 lg:gap-4 grid-cols-1 mt-3">
                                            {!registration.isTrial &&
                                            [POS_PLAN_ID.STANDARD].includes(
                                                registration.extraAppPlanId,
                                            ) ? (
                                                <Button
                                                    type="primary"
                                                    icon={<RightCircleOutlined />}
                                                    className="w-full flex justify-center lg:mt-0 mt-4"
                                                    onClick={() => {
                                                        setTypeModal(
                                                            SubscriptionOrderTypeEnum.ADD_ON_PLAN,
                                                        );
                                                        setIsOpenUpgradeModal(true);
                                                    }}
                                                >
                                                    {t('plan_form.actions.add_on_plan')}
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                        </section>
                                    </Col>

                                    <Col xs={24} xl={11} className="rounded-lg shadow-lg p-6">
                                        {[POS_PLAN_ID.STANDARD, POS_PLAN_ID.STANDARD_FNB].includes(
                                            registration.extraAppPlanId,
                                        ) ? (
                                            <div className="mb-10">
                                                <div>
                                                    <p className="text-xl">
                                                        {t(`plan_form.printer_noti`, {
                                                            usedPrinter:
                                                                registration.usedPlan
                                                                    .printerQuantity || 0,
                                                            qtyPrinter: LIMIT_PRINTER_QTY,
                                                        })}
                                                    </p>
                                                    <Progress
                                                        percent={Math.round(
                                                            ((registration.usedPlan
                                                                .printerQuantity || 0) *
                                                                100) /
                                                                LIMIT_PRINTER_QTY,
                                                        )}
                                                    />
                                                </div>

                                                <div className="mt-10">
                                                    <p className="text-xl">
                                                        {t(`plan_form.connect_device_noti`, {
                                                            usedConnectDevice:
                                                                registration.usedPlan
                                                                    .connectDeviceQuantity || 0,
                                                            qtyConnectDevice:
                                                                LIMIT_CONNECT_DEVICE_QTY,
                                                        })}
                                                    </p>
                                                    <Progress
                                                        percent={Math.round(
                                                            ((registration.usedPlan
                                                                .connectDeviceQuantity || 0) *
                                                                100) /
                                                                LIMIT_CONNECT_DEVICE_QTY,
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <div>
                                            <p className="text-xl">
                                                {t(`plan_form.table_noti`, {
                                                    usedTable: registration.usedPlan.table || 0,
                                                    qtyTable: registration.qtyTable,
                                                })}
                                            </p>
                                            <Progress
                                                percent={Math.round(
                                                    ((registration.usedPlan.table || 0) * 100) /
                                                        registration.qtyTable,
                                                )}
                                            />
                                        </div>
                                        {registration.isTrial || isFreePlan ? (
                                            <></>
                                        ) : (
                                            <Button
                                                type="default"
                                                icon={<RightCircleOutlined />}
                                                className="w-1/2 flex justify-center mt-2"
                                                onClick={() => {
                                                    setTypeModal(
                                                        SubscriptionOrderTypeEnum.UPGRADE_TABLE,
                                                    );
                                                    setIsOpenUpgradeModal(true);
                                                }}
                                            >
                                                {t('plan_form.actions.upgrade_table')}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </section>

                            <SubscriptionOrder
                                isRefetch={isRefetch}
                                setIsRefetch={setIsRefetch}
                                handlePaymentSubscriptionOrder={(record) => {
                                    setSubscriptionOrderId(record.id);
                                    handlePaymentSubscriptionOrder(record);
                                }}
                            />

                            {isOpenUpgradeModal && typeModal ? (
                                <>
                                    {[SubscriptionOrderTypeEnum.UPGRADE_LICENSE].includes(
                                        typeModal,
                                    ) && (
                                        <UpgradeLicenseModal
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handlePayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                            paymentFormula={paymentFormula}
                                            remainingMonths={remainingMonths}
                                        />
                                    )}

                                    {[
                                        SubscriptionOrderTypeEnum.UPGRADE_TABLE,
                                        SubscriptionOrderTypeEnum.RENEW_LICENSE,
                                    ].includes(typeModal) && (
                                        <UpgradeModal
                                            type={typeModal}
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handlePayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                            paymentFormula={paymentFormula}
                                            remainingMonths={remainingMonths}
                                        />
                                    )}

                                    {[SubscriptionOrderTypeEnum.ADD_ON_PLAN].includes(
                                        typeModal,
                                    ) && (
                                        <AddOnPlanModal
                                            type={typeModal}
                                            isOpen={isOpenUpgradeModal}
                                            onClose={handleCloseModal}
                                            handlePayment={handlePayment}
                                            isLoading={isLoading}
                                            registration={registration}
                                            paymentFormula={paymentFormula}
                                            remainingMonths={remainingMonths}
                                        />
                                    )}
                                </>
                            ) : (
                                <></>
                            )}

                            {qrUrl && (
                                <>
                                    <QRModal
                                        url={qrUrl}
                                        isOpen={isOpenQrModal}
                                        totalPrice={totalPayment}
                                        onClose={() => {
                                            setQrUrl(undefined);
                                            setIsOpenQrModal(false);
                                            handleRefetchSubscriptionOrder();
                                            setSubscriptionOrderId(undefined);
                                        }}
                                    />
                                </>
                            )}

                            {subscriptionOrderId && (
                                <NotificationPaymentModal
                                    objectId={subscriptionOrderId || ''}
                                    onClose={handleNotiResetState}
                                    onCloseQrModal={() => {
                                        setQrUrl(undefined);
                                        setIsOpenQrModal(false);
                                    }}
                                    subTitle={subTitle}
                                    title={title}
                                    id={registration.id}
                                />
                            )}

                            {subTitle && (
                                <NotificationInformationModal
                                    onClose={handleNotiResetState}
                                    title={title || ''}
                                    subTitle={subTitle || ''}
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Spin>
            )}
        </>
    );
};
