import './styles.scss';

import { useSelect } from '@refinedev/antd';
import { CrudFilters } from '@refinedev/core';
import { Select, SelectProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IContact } from '../../../interfaces/contact';

export const USER_RESOURCE_API = 'v1/users';

export const SelectUser: React.FC<SelectProps & { partnerId?: string | null }> = (props) => {
    const { t } = useTranslation(['common']);
    const [pageIndex, setPageIndex] = useState(1);
    const [currentData, setCurrentData] = useState<IContact.IContact[]>([]);
    const { value, onChange, className, placeholder, ...res } = props;
    const [filters, setFilters] = useState<CrudFilters>([]);

    const { selectProps, queryResult } = useSelect<IContact.IContact>({
        resource: `${USER_RESOURCE_API}/tenant/staffs`,
        optionValue: 'id',
        debounce: 500,
        onSearch: (value) => {
            setPageIndex(1);
            setCurrentData([]);
            setFilters([
                {
                    field: 'filter',
                    operator: 'eq',
                    value,
                },
            ]);

            if (props.partnerId) {
                filters.push({
                    field: 'userId',
                    operator: 'eq',
                    value: value,
                });
            }
            return [];
        },
        filters: props.partnerId
            ? [
                ...filters,
                {
                    field: 'partnerId',
                    operator: 'eq',
                    value: props.partnerId,
                },
            ]
            : filters,

        pagination: {
            mode: 'server',
            current: pageIndex,
        },
        queryOptions: {
            onSuccess: (data) => {
                setCurrentData(currentData.concat(data.data));
            },
        },
        sort: [
            {
                field: 'name',
                order: 'asc',
            },
        ],
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_staffs'),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_staffs_failed'),
                };
            }
        },
    });

    return (
        <Select
            {...selectProps}
            {...props}
            placeholder="Select User"
            getPopupContainer={(trigger) => trigger.parentNode}
            allowClear
            className="select-contact"
            onPopupScroll={async (e: any) => {
                const { target } = e;
                if (
                    (target as any).scrollTop + (target as any).offsetHeight ===
                    (target as any).scrollHeight
                ) {
                    if (
                        queryResult.data &&
                        currentData.length < queryResult.data?.total &&
                        !queryResult.isRefetching &&
                        !queryResult.isFetching
                    ) {
                        setPageIndex(pageIndex + 1);
                        await queryResult.refetch();
                    }
                }
            }}
            options={currentData?.map((item) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
            }))}
            loading={queryResult.isRefetching || queryResult.isFetching}
            onClear={() => {
                setPageIndex(1);
                setCurrentData([]);
                setFilters([]);
                if (!filters.length) {
                    queryResult.refetch();
                }
            }}
        />
    );
};
