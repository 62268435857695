import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import {
    DATE_FORMAT,
    SERVICE_OTHER_OPTION_ID,
    WHITE_LABEL_SETTING_RESOURCE,
} from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { QuoteStatus } from '@enums/quote-status.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IInvoice } from '@interfaces/invoice';
import { IService } from '@interfaces/service';
import { IUser } from '@interfaces/user';
import { WLSetting } from '@interfaces/white-label-setting';
import { useSelect } from '@refinedev/antd';
import {
    BaseKey,
    BaseRecord,
    useApiUrl,
    useCustom,
    useGetIdentity,
    useNavigation,
    useOne,
    usePermissions,
    useUpdate,
} from '@refinedev/core';
import { formatPrice, formatPriceBaseOnCurrency } from '@utils/resource';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Spin,
} from 'antd';
import { RemoveActionIcon } from 'components/layout/icons/remove-action';
import dayjs from 'dayjs';
import { CurrencyType } from 'enums/currency-type.enum';
import { keyBy } from 'lodash';
import { IQuoteForm } from 'pages/quote/create';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { maskPhone } from 'utils/string';

import { TextEditor } from '../../components/modules/editor';
import Response from '../../data-access/responses/response';
import { WLSettingFields } from '../wl-setting/constants/white-label-setting-fields';

interface IEditInvoiceForm {
    date: dayjs.Dayjs | Date | string | null;
    dueDate: dayjs.Dayjs | Date | string | null;
    description: string;
    items: IEditInvoiceItemForm[];
    termsAndConditions: string;
}

interface IEditInvoiceItemForm {
    qty: number;
    margin: number;
    unitPrice: number;
    frequency: ProductFrequencyEnum;
    serviceId?: string;
    productId?: string;
    productName?: string;
    total?: number;
}

export const InvoiceEditIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const [form] = Form.useForm<IEditInvoiceForm>();
    const { id } = useParams();
    const { goBack } = useNavigation();
    const [totalOrder, setTotalOrder] = useState<number>(0);
    const [serviceUpdated, setServiceUpdated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<QuoteStatus>(QuoteStatus.Draft);
    const { mutate } = useUpdate<BaseRecord>();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const isTenant = userIdentity?.userType === UserTypeEnum.TENANT;
    const { data: permissions } = usePermissions<string[]>();
    const isCanEditInvoice = (permissions || []).includes(PERMISSIONS.WL_INVOICE_EDIT);

    const apiUrl = useApiUrl();
    const wlSettingResource = `${apiUrl}/${WHITE_LABEL_SETTING_RESOURCE}`;

    const { data: wlSetting } = useCustom<Response<WLSetting.IWLSetting>>({
        url: wlSettingResource,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    const {
        data: invoiceData,
        isLoading: loadingEditInvoice,
        error,
    } = useOne<IInvoice.InvoiceDetails>({
        resource: 'v1/invoices',
        id: id as BaseKey,
        errorNotification: {
            type: 'error',
            message: t('quotes.error.invoice_has_expired'),
        },
    });

    const maxMargin =
        wlSetting?.data?.data?.data?.[WLSettingFields.MAXIMUM_MARGIN_PERCENTAGE] || '0';
    const _charge = wlSetting?.data?.data?.data?.[WLSettingFields.SERVICE_CHARGE_PERCENTAGE] || '0';

    const { selectProps: serviceSelectProps, queryResult } = useSelect<IService>({
        resource: 'v1/services/active-services',
        optionLabel: 'name',
        optionValue: 'id',
        pagination: {
            mode: 'server',
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_services', {
                        ns: 'common',
                    }),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_services_failed', { ns: 'common' }),
                };
            }
        },
    });

    const services: any = keyBy(queryResult.data?.data, 'id');

    const onChangeService = (serviceId: string, index: number) => {
        const items = form.getFieldValue('items');
        items[index].productId = undefined;
        items[index].productName = null;

        items[index].unitPrice = null;
        items[index].qty = items[index].qty || 1;
        items[index].frequency = null;
        items[index].margin = 0;

        form.setFieldsValue({ items: items });
        setServiceUpdated(!serviceUpdated);
    };

    const onChangeProduct = (productId: string, index: number) => {
        const items = form.getFieldValue('items');
        const products = services[items[index].serviceId].products;
        const selectedProduct = products.find((p: any) => p.id === productId);

        items[index].productName = selectedProduct.name;
        items[index].unitPrice = selectedProduct.price;
        items[index].qty = 1;
        items[index].frequency = selectedProduct.frequency;

        form.setFieldsValue({ items: items });
        setTotalOrder(getTotalPrice());
        setServiceUpdated(!serviceUpdated);
    };

    const getTotalPrice = () => {
        const items = form?.getFieldValue('items');
        if (items) {
            let total = 0;
            for (const item of items) {
                let itemTotal = item?.qty * item?.unitPrice || 0;
                if (item?.margin) {
                    itemTotal = (itemTotal * item.margin) / 100 + itemTotal;
                }

                total += itemTotal;
            }

            return total;
        }
        return 0;
    };

    const onChangeStatus = () => {
        setServiceUpdated(!serviceUpdated);
        onChangeMargin();
    };

    const onChangeMargin = () => {
        setTotalOrder(getTotalPrice());
        setServiceUpdated(!serviceUpdated);
    };

    const onFinish = (values: IEditInvoiceForm) => {
        // setIsLoading(true);
        for (const item of values.items) {
            const basePrice = +Number.parseFloat(`${item?.unitPrice}`).toFixed(2);
            item.unitPrice = +basePrice * 100;
            item.total = item?.unitPrice * item?.qty;
        }

        mutate(
            {
                resource: 'v1/invoices/edit',
                id: id ?? '',
                values: values,
                successNotification: {
                    message: t('quotes.message.edit_invoice_successfully'),
                    type: 'success',
                },
            },
            {
                onError: (error, _, __) => {
                    switch (error?.message) {
                        case 'invoice_not_found':
                            notification.error({
                                message: t('quotes.error.invoice_not_found'),
                            });
                            break;
                        case 'invoice_was_not_pending':
                            notification.error({
                                message: t('quotes.error.invoice_was_not_pending'),
                            });
                            break;
                        default:
                            notification.error({
                                message: t('quotes.error.change_invoice_status_failed'),
                            });
                    }
                    setIsLoading(false);
                    return;
                },
                onSuccess: (_) => {
                    goBack();
                },
            },
        );
    };

    const submitForm = (status: QuoteStatus) => {
        setStatus(status);
        form.submit();
    };

    const disablePaymentDate = (current: dayjs.Dayjs): boolean => {
        return current < dayjs().startOf('day');
    };

    const disableDueDate = (current: dayjs.Dayjs): boolean => {
        const paymentDate = form.getFieldValue('date');
        return current < (paymentDate ? dayjs(paymentDate).startOf('day') : dayjs().startOf('day'));
    };

    const selectServiceOtherOptions = {
        label: t('quotes.fields.other_service.label'),
        value: SERVICE_OTHER_OPTION_ID,
    };

    const initEditInvoiceForm = () => {
        const _invoiceData = invoiceData?.data;
        form.setFieldsValue({
            date: _invoiceData?.date ? dayjs(_invoiceData?.date) : null,
            dueDate: _invoiceData?.dueDate ? dayjs(_invoiceData?.dueDate) : null,
            description: _invoiceData?.description ?? '',
            termsAndConditions: _invoiceData?.termsAndConditions ?? '',
            items: (_invoiceData?.items || []).map((_item) => ({
                qty: _item.qty ?? 1,
                margin: _item.margin ?? 0,
                unitPrice: _item.unitPrice,
                frequency: _item.frequency,
                serviceId: _item.serviceId ?? '',
                productId: _item.productId ?? '',
                productName: _item.productName ?? '',
                charge: _item.charge ?? 0,
            })),
        });
    };

    useEffect(() => {
        initEditInvoiceForm();
        onChangeStatus();
    }, [invoiceData?.data]);

    return (
        <>
            <Spin spinning={loadingEditInvoice}>
                <section className="invoice-container">
                    <div className="block-heading edit-heading">
                        <span>{t('quotes.heading.edit_invoice_title')}</span>
                        <div className="header-actions">
                            <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>
                            <Button
                                type="primary"
                                disabled={status === QuoteStatus.Draft && isLoading}
                                loading={status === QuoteStatus.Pending && isLoading}
                                onClick={() => submitForm(QuoteStatus.Pending)}
                            >
                                {t('actions.save')}
                            </Button>
                        </div>
                    </div>
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={{ items: [{ qty: 1, margin: 0 }] }}
                        onFinish={onFinish}
                    >
                        <div className="section details-section">
                            <div className="section-header">
                                {t('quotes.heading.invoiceInformation')}
                            </div>
                            <Row gutter={20}>
                                <Col xs={24} md={8} xl={8} className="user-info-col">
                                    <div className="user-info">
                                        <div className="name">{invoiceData?.data?.contactName}</div>
                                        <div className="no-space">
                                            {invoiceData?.data.contactEmail}
                                        </div>
                                        <div className="no-space">
                                            {invoiceData?.data?.contactPhone
                                                ? maskPhone(invoiceData?.data?.contactPhone)
                                                : ''}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={0} xl={2}></Col>
                                <Col xs={24} md={8} xl={7}>
                                    <Form.Item
                                        label={t('quotes.fields.paymentDate.label')}
                                        name="date"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('quotes.fields.paymentDate.required'),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            getPopupContainer={(trigger) =>
                                                trigger?.parentNode as HTMLElement
                                            }
                                            format={DATE_FORMAT}
                                            disabledDate={(current) => disablePaymentDate(current)}
                                            onChange={() => form.resetFields(['dueDate'])}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} xl={7}>
                                    <Form.Item
                                        label={t('quotes.fields.paymentDueDate.label')}
                                        name="dueDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('quotes.fields.paymentDueDate.required'),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            getPopupContainer={(trigger) =>
                                                trigger?.parentNode as HTMLElement
                                            }
                                            format={DATE_FORMAT}
                                            disabledDate={(current) => disableDueDate(current)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="section">
                            <Row gutter={20}>
                                <Col md={24}>
                                    <Form.Item
                                        label={t('quotes.fields.description.label')}
                                        name="description"
                                    >
                                        <TextEditor height="300px" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="section">
                            <div className="section-header">{t('quotes.heading.itemDetails')}</div>
                            <Row className="item-list">
                                <Col span={24}>
                                    <Row
                                        className={` ${
                                            window.screen.width < 578 ? 'table-header-none' : ''
                                        } table-header`}
                                    >
                                        <Col span={6}>{t('quotes.fields.item.label')}</Col>
                                        <Col span={6}>{t('quotes.fields.unitPrice.label')}</Col>
                                        <Col span={4}>{t('quotes.fields.qty.label')}</Col>
                                        <Col span={4}>{t('quotes.fields.frequency.label')}</Col>
                                        <Col span={4}>{t('quotes.fields.total.label')}</Col>
                                    </Row>
                                    <Form.List name="items">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(
                                                    (
                                                        { key, name, ...restField },
                                                        index: number,
                                                    ) => {
                                                        const formItem =
                                                            form.getFieldValue('items')[index];
                                                        const type =
                                                            services[formItem?.serviceId]?.type ||
                                                            '';

                                                        let total =
                                                            formItem?.unitPrice * formItem?.qty ||
                                                            0;

                                                        const isSelectedOtherService =
                                                            formItem?.serviceId ===
                                                            selectServiceOtherOptions.value;
                                                        if (!isNaN(+formItem?.margin)) {
                                                            total +=
                                                                (total * formItem.margin) / 100;
                                                        } else formItem.margin = 0;

                                                        return (
                                                            <Row
                                                                className="list-product-item  item-custom"
                                                                key={key}
                                                            >
                                                                <Col xs={24} sm={6}>
                                                                    {isCanEditInvoice && (
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[
                                                                                    name,
                                                                                    'serviceId',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        required:
                                                                                            true,
                                                                                        message: t(
                                                                                            'quotes.fields.item.required',
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                                label={t(
                                                                                    'quotes.fields.item.label',
                                                                                )}
                                                                            >
                                                                                {type ===
                                                                                    UserTypeEnum.TENANT ||
                                                                                userIdentity?.userType !==
                                                                                    UserTypeEnum.TENANT ? (
                                                                                    <Select
                                                                                        getPopupContainer={(
                                                                                            trigger,
                                                                                        ) =>
                                                                                            trigger.parentNode
                                                                                        }
                                                                                        options={
                                                                                            isTenant
                                                                                                ? [
                                                                                                      ...(serviceSelectProps.options?.filter(
                                                                                                          (
                                                                                                              item,
                                                                                                          ) =>
                                                                                                              item.value &&
                                                                                                              services[
                                                                                                                  item
                                                                                                                      .value
                                                                                                              ]
                                                                                                                  ?.type ===
                                                                                                                  UserTypeEnum.TENANT,
                                                                                                      ) ||
                                                                                                          []),
                                                                                                      selectServiceOtherOptions,
                                                                                                  ]
                                                                                                : [
                                                                                                      ...(serviceSelectProps.options ||
                                                                                                          []),
                                                                                                  ]
                                                                                        }
                                                                                        showSearch={
                                                                                            false
                                                                                        }
                                                                                        onChange={(
                                                                                            item: any,
                                                                                        ) =>
                                                                                            onChangeService(
                                                                                                item,
                                                                                                index,
                                                                                            )
                                                                                        }
                                                                                        placeholder={
                                                                                            'Select service'
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <Select
                                                                                        options={[
                                                                                            {
                                                                                                label: services[
                                                                                                    formItem
                                                                                                        .serviceId
                                                                                                ]
                                                                                                    ?.name,
                                                                                                value: services[
                                                                                                    formItem
                                                                                                        .serviceId
                                                                                                ]
                                                                                                    ?.id,
                                                                                            },
                                                                                        ]}
                                                                                        disabled={
                                                                                            true
                                                                                        }
                                                                                    ></Select>
                                                                                )}
                                                                            </Form.Item>

                                                                            {formItem?.serviceId &&
                                                                                isSelectedOtherService && (
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        label={t(
                                                                                            'quotes.fields.product.label',
                                                                                        )}
                                                                                        name={[
                                                                                            name,
                                                                                            'productName',
                                                                                        ]}
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    t(
                                                                                                        'quotes.fields.product.required',
                                                                                                    ),
                                                                                            },
                                                                                        ]}
                                                                                        className="product-custom"
                                                                                    >
                                                                                        <Input
                                                                                            onChange={() => {
                                                                                                form.validateFields(
                                                                                                    [
                                                                                                        [
                                                                                                            'items',
                                                                                                            index,
                                                                                                            'unitPrice',
                                                                                                        ],
                                                                                                        [
                                                                                                            'items',
                                                                                                            index,
                                                                                                            'frequency',
                                                                                                        ],
                                                                                                    ],
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Form.Item>
                                                                                )}

                                                                            {formItem?.serviceId &&
                                                                                !isSelectedOtherService && (
                                                                                    <Form.Item
                                                                                        {...restField}
                                                                                        name={[
                                                                                            name,
                                                                                            'productId',
                                                                                        ]}
                                                                                        label={t(
                                                                                            'quotes.fields.product.label',
                                                                                        )}
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    t(
                                                                                                        'quotes.fields.product.required',
                                                                                                    ),
                                                                                            },
                                                                                        ]}
                                                                                        className="product-custom"
                                                                                    >
                                                                                        <Select
                                                                                            getPopupContainer={(
                                                                                                trigger,
                                                                                            ) =>
                                                                                                trigger.parentNode
                                                                                            }
                                                                                            showSearch={
                                                                                                false
                                                                                            }
                                                                                            placeholder={t(
                                                                                                'quotes.fields.product.placeholder',
                                                                                            )}
                                                                                            onChange={(
                                                                                                item,
                                                                                            ) =>
                                                                                                onChangeProduct(
                                                                                                    item,
                                                                                                    index,
                                                                                                )
                                                                                            }
                                                                                            disabled={
                                                                                                isTenant &&
                                                                                                type ===
                                                                                                    UserTypeEnum.WHITELABEL
                                                                                            }
                                                                                        >
                                                                                            {services[
                                                                                                formItem?.serviceId
                                                                                            ]?.products.map(
                                                                                                (
                                                                                                    product: any,
                                                                                                ) => {
                                                                                                    return (
                                                                                                        <Select.Option
                                                                                                            key={
                                                                                                                product.id
                                                                                                            }
                                                                                                            value={
                                                                                                                product.id
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                product.name
                                                                                                            }
                                                                                                        </Select.Option>
                                                                                                    );
                                                                                                },
                                                                                            )}
                                                                                        </Select>
                                                                                    </Form.Item>
                                                                                )}
                                                                        </>
                                                                    )}
                                                                </Col>
                                                                <Col xs={24} sm={6}>
                                                                    <>
                                                                        {isTenant &&
                                                                        isSelectedOtherService ? (
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[
                                                                                    name,
                                                                                    'unitPrice',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        required:
                                                                                            true,
                                                                                        message: t(
                                                                                            'quotes.fields.unitPrice.required',
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                                label={t(
                                                                                    'quotes.fields.unitPrice.label',
                                                                                )}
                                                                            >
                                                                                <InputNumber
                                                                                    precision={2}
                                                                                    formatter={(
                                                                                        value,
                                                                                    ) =>
                                                                                        `${value}`.replace(
                                                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                                                            ',',
                                                                                        )
                                                                                    }
                                                                                    parser={(
                                                                                        value,
                                                                                    ) =>
                                                                                        value!.replace(
                                                                                            /\$\s?|(,*)/g,
                                                                                            '',
                                                                                        )
                                                                                    }
                                                                                    onChange={
                                                                                        onChangeStatus
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        ) : (
                                                                            <span className="block mt-3 mb-8 unit-price-custom invoice-price-custom">
                                                                                <span className="custom-label">
                                                                                    {t(
                                                                                        'quotes.fields.unitPrice.label',
                                                                                    )}
                                                                                </span>
                                                                                {formatPrice(
                                                                                    formItem?.unitPrice *
                                                                                        1,
                                                                                    CurrencyType.Vnd,
                                                                                )}
                                                                            </span>
                                                                        )}

                                                                        {type ===
                                                                            UserTypeEnum.WHITELABEL &&
                                                                        Number.parseInt(_charge) >
                                                                            0 ? (
                                                                            <span className="charge">
                                                                                <b>
                                                                                    {t(
                                                                                        'quotes.fields.service_fee.label',
                                                                                    )}
                                                                                    :{' '}
                                                                                </b>
                                                                                {`${Number.parseInt(
                                                                                    _charge,
                                                                                )}%`}
                                                                            </span>
                                                                        ) : null}
                                                                    </>

                                                                    {isCanEditInvoice &&
                                                                        formItem?.serviceId &&
                                                                        type ===
                                                                            UserTypeEnum.TENANT && (
                                                                            <Form.Item
                                                                                {...restField}
                                                                                label={t(
                                                                                    'quotes.fields.margin.label',
                                                                                )}
                                                                                name={[
                                                                                    name,
                                                                                    'margin',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        validator: (
                                                                                            _,
                                                                                            value,
                                                                                        ) => {
                                                                                            if (
                                                                                                maxMargin &&
                                                                                                value >
                                                                                                    +maxMargin
                                                                                            ) {
                                                                                                return Promise.reject(
                                                                                                    t(
                                                                                                        'common.max_margin_invalid',
                                                                                                        {
                                                                                                            ns: 'common',
                                                                                                            maxMargin:
                                                                                                                maxMargin,
                                                                                                        },
                                                                                                    ),
                                                                                                );
                                                                                            }
                                                                                            return Promise.resolve();
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        required:
                                                                                            true,
                                                                                        message: t(
                                                                                            'quotes.fields.margin.required',
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                                className="product-custom custom-margin"
                                                                            >
                                                                                <InputNumber
                                                                                    defaultValue={0}
                                                                                    min={0}
                                                                                    precision={0}
                                                                                    onChange={
                                                                                        onChangeStatus
                                                                                    }
                                                                                    placeholder={t(
                                                                                        'quotes.fields.margin.placeholder',
                                                                                    )}
                                                                                />
                                                                            </Form.Item>
                                                                        )}
                                                                </Col>
                                                                <Col xs={24} sm={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'qty']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t(
                                                                                    'quotes.fields.qty.required',
                                                                                ),
                                                                            },
                                                                            {
                                                                                min: 1,
                                                                                type: 'number',
                                                                                message: t(
                                                                                    'quotes.fields.qty.qty_not_less_than_one',
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        label={t(
                                                                            'quotes.fields.qty.label',
                                                                        )}
                                                                    >
                                                                        <InputNumber
                                                                            min={1}
                                                                            defaultValue={1}
                                                                            step={1}
                                                                            precision={0}
                                                                            onChange={
                                                                                onChangeStatus
                                                                            }
                                                                            disabled={
                                                                                isTenant &&
                                                                                type ===
                                                                                    UserTypeEnum.WHITELABEL
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={4}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'frequency']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t(
                                                                                    'quotes.fields.frequency.required',
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        label={t(
                                                                            'quotes.fields.frequency.label',
                                                                        )}
                                                                    >
                                                                        <Select
                                                                            getPopupContainer={(
                                                                                trigger,
                                                                            ) => trigger.parentNode}
                                                                            disabled={
                                                                                isTenant &&
                                                                                type ===
                                                                                    UserTypeEnum.WHITELABEL
                                                                            }
                                                                        >
                                                                            <Select.Option
                                                                                value={
                                                                                    ProductFrequencyEnum.ONE_TIME_PAYMENT
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                {t(
                                                                                    'services.frequency.' +
                                                                                        ProductFrequencyEnum.ONE_TIME_PAYMENT,
                                                                                    {
                                                                                        ns: 'common',
                                                                                    },
                                                                                )}
                                                                            </Select.Option>
                                                                            <Select.Option
                                                                                value={
                                                                                    ProductFrequencyEnum.PER_MONTH
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                {t(
                                                                                    'services.frequency.' +
                                                                                        ProductFrequencyEnum.PER_MONTH,
                                                                                    {
                                                                                        ns: 'common',
                                                                                    },
                                                                                )}
                                                                            </Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col
                                                                    xs={24}
                                                                    sm={4}
                                                                    className="total-wrapper"
                                                                >
                                                                    <span className="total-price">
                                                                        {t(
                                                                            'quotes.fields.total.label',
                                                                        )}
                                                                    </span>
                                                                    <div className="total-container">
                                                                        <span>
                                                                            {formatPriceBaseOnCurrency(
                                                                                total,
                                                                                CurrencyType.Vnd,
                                                                            )}
                                                                        </span>
                                                                        {index > 0 &&
                                                                            type ===
                                                                                UserTypeEnum.TENANT && (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        remove(
                                                                                            name,
                                                                                        );
                                                                                        onChangeStatus();
                                                                                    }}
                                                                                    className="ml-4"
                                                                                >
                                                                                    <RemoveActionIcon />
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    },
                                                )}
                                                {isCanEditInvoice && !!invoiceData && (
                                                    <div className="btn-add-container">
                                                        <Button
                                                            onClick={() => add({ qty: 1 })}
                                                            type="link"
                                                            icon={<PlusOutlined />}
                                                        >
                                                            {t('quotes.actions.addItem')}
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>
                            <div className="sum-total flex">
                                <span>{t('quotes.heading.totalPrice')}</span>
                                <span className="ml-auto">{formatPrice(totalOrder)}</span>
                            </div>
                        </div>
                        <div className="section">
                            <div className="section-header">{t('quotes.fields.tc.label')}</div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <Form.Item name="termsAndConditions">
                                        <TextEditor />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </section>
            </Spin>
        </>
    );
};
