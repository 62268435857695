import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { TextEditor } from '@components/modules/editor';
import { SelectContact } from '@components/modules/select-contact';
import {
    DATE_FORMAT,
    SERVICE_OTHER_OPTION_ID,
    WHITE_LABEL_SETTING_RESOURCE,
} from '@constants/index.constant';
import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { QuoteStatus } from '@enums/quote-status.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IContact } from '@interfaces/contact';
import { IOrder } from '@interfaces/order';
import { IService } from '@interfaces/service';
import { IUser } from '@interfaces/user';
import { WLSetting } from '@interfaces/white-label-setting';
import { useSelect } from '@refinedev/antd';
import {
    BaseRecord,
    useApiUrl,
    useCreate,
    useCustom,
    useGetIdentity,
    useNavigation,
} from '@refinedev/core';
import Response from '@responses/response';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { CurrencyType } from 'enums/currency-type.enum';
import { keyBy } from 'lodash';
import { WLSettingFields } from 'pages/wl-setting/constants/white-label-setting-fields';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatPrice, formatPriceBaseOnCurrency } from '../../utils/resource';

export const InvoiceCreateIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const [form] = Form.useForm();

    const [contactId, setContactId] = useState<string | null>(null);
    const [orderId, setOrderId] = useState<string | null>(null);
    const [orders, setOrders] = useState<IOrder.OrderList[] | null>(null);
    const [totalOrder, setTotalOrder] = useState<number>(0);

    const [serviceUpdated, setServiceUpdated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<QuoteStatus>(QuoteStatus.Draft);

    const { mutate } = useCreate<BaseRecord>();
    const { goBack } = useNavigation();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();
    const isTenant = userIdentity?.userType === UserTypeEnum.TENANT;
    const isPartner =
        userIdentity?.userType === UserTypeEnum.WHITELABEL ||
        userIdentity?.userType === UserTypeEnum.AFFILIATE_PARTNER;
    const apiUrl = useApiUrl();

    const wlSettingResource = `${apiUrl}/${WHITE_LABEL_SETTING_RESOURCE}`;

    const { data: wlSetting } = useCustom<Response<WLSetting.IWLSetting>>({
        url: wlSettingResource,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });

    const maxMargin =
        wlSetting?.data?.data?.data?.[WLSettingFields.MAXIMUM_MARGIN_PERCENTAGE] || '0';
    const _charge = wlSetting?.data?.data?.data?.[WLSettingFields.SERVICE_CHARGE_PERCENTAGE] || '0';

    const { refetch: fetchById } = useCustom<Response<IOrder.OrderDetails>>({
        url: `${apiUrl}/v1/orders/invoice-create/${orderId}`,
        method: 'get',
        queryOptions: {
            enabled: false,
        },
        errorNotification: (error: any, values, resource) => {
            if (error?.message === 'order_has_been_created_invoice') {
                return {
                    message: t('notifications.errors.order_has_been_created_invoice', {
                        ns: 'common',
                    }),
                    type: 'error',
                };
            }
            return {
                message: error?.message,
                type: 'error',
            };
        },
    });

    const { refetch: fetchOrderByContactId } = useCustom<IOrder.OrderList[]>({
        url: `${apiUrl}/v1/orders/not-invoiced/${contactId}`,
        method: 'get',
        queryOptions: {
            enabled: false,
        },
    });

    useEffect(() => {
        if (contactId) {
            fetchOrderByContactId().then((res) => {
                if (res && res.data) {
                    setOrders(res.data.data);
                }
            });
        }
    }, [contactId]);

    useEffect(() => {
        if (orderId) {
            fetchById().then((res) => {
                if (res && res?.data?.data?.data) {
                    const orderData = res.data.data.data;

                    const items = orderData.items.map((item) => {
                        item.unitPrice = item.unitPrice ?? 0;

                        return item;
                    });

                    form.setFieldsValue({
                        id: orderData.id,
                        contactId: orderData.contactId,
                        items: items,
                    });

                    if (items?.length) {
                        setTotalOrder(getTotalPrice());
                    }
                }
            });
        }
    }, [orderId]);

    const [pageIndex, setPageIndex] = useState(1);
    const { selectProps, queryResult: partnerQueryResult } = useSelect<IContact.IContact>({
        resource: 'v1/contacts',
        optionLabel: 'fullName',
        optionValue: 'id',
        onSearch: (value) => {
            setPageIndex(1);
            setCurrentData([]);
            return [
                {
                    field: 'filter',
                    operator: 'eq',
                    value,
                },
            ];
        },
        pagination: {
            mode: 'server',
            current: pageIndex,
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_contacts', {
                        ns: 'common',
                    }),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_contacts_failed', { ns: 'common' }),
                };
            }
        },
        queryOptions: {
            onSuccess: (data) => {
                setCurrentData(currentData.concat(data.data));
            },
            // getNextPageParam: (data) => {
            //     console.log('getNextPageParam', data);
            // },
            // select: (data) => {
            //     // if (newData.length) {
            //     //     data.data = data.data.concat(newData);
            //     //     setNewData([]);
            //     // }
            //     // data.data = currentData;
            //     console.log('select', data);
            //     return data;
            // },
            keepPreviousData: true,
            staleTime: Infinity,
        },
    });
    const { options, ...partnerSelectProps } = selectProps;
    const [currentData, setCurrentData] = useState<IContact.IContact[]>([]);

    const { selectProps: serviceSelectProps, queryResult } = useSelect<IService>({
        resource: 'v1/services/active-services',
        optionLabel: 'name',
        optionValue: 'id',
        pagination: {
            mode: 'server',
        },
        errorNotification: (error, _, __) => {
            if ((error as { statusCode: number })?.statusCode === 403) {
                return {
                    type: 'error',
                    message: t('notifications.errors.not_enough_permission_services', {
                        ns: 'common',
                    }),
                };
            } else {
                return {
                    type: 'error',
                    message: t('notifications.errors.get_services_failed', { ns: 'common' }),
                };
            }
        },
    });

    const services: any = keyBy(queryResult.data?.data, 'id');

    const onChangeService = (serviceId: string, index: number) => {
        const items = form.getFieldValue('items');
        items[index].productId = undefined;
        items[index].productName = null;

        items[index].unitPrice = null;
        items[index].qty = items[index].qty || 1;
        items[index].frequency = null;
        items[index].margin = 0;

        form.setFieldsValue({ items: items });
        setServiceUpdated(!serviceUpdated);
    };

    const onChangeProduct = (productId: string, index: number) => {
        const items = form.getFieldValue('items');
        const products = services[items[index].serviceId].products;
        const selectedProduct = products.find((p: any) => p.id === productId);

        items[index].productName = selectedProduct.name;
        items[index].unitPrice = selectedProduct.price;
        items[index].qty = 1;
        items[index].frequency = selectedProduct.frequency;

        form.setFieldsValue({ items: items });
        setTotalOrder(getTotalPrice());
        setServiceUpdated(!serviceUpdated);
    };

    const getTotalPrice = () => {
        const items = form?.getFieldValue('items');

        if (items) {
            let total = 0;
            for (const item of items) {
                let itemTotal = item?.qty * item?.unitPrice || 0;
                if (item?.margin) {
                    itemTotal = (itemTotal * item.margin) / 100 + itemTotal;
                }

                total += itemTotal;
            }

            return total;
        }
        return 0;
    };

    const onChangeStatus = () => {
        setServiceUpdated(!serviceUpdated);
        onChangeMargin();
    };

    const onChangeMargin = () => {
        setTotalOrder(getTotalPrice());
        setServiceUpdated(!serviceUpdated);
    };

    const onChangeOrder = (val: any) => {
        if (val) {
            setOrderId(val);
        } else {
            setOrderId(null);
            form.setFieldsValue({ items: [{}] });
        }
    };

    const onChangeContact = (val: any) => {
        setContactId(val);
        if (form.getFieldValue('orderId')) {
            form.resetFields(['items', 'orderId']);
            setOrderId(null);
        }
    };

    const onFinish = (values: any) => {
        setIsLoading(true);
        for (const item of values.items) {
            const basePrice: any = Number.parseFloat(item.unitPrice).toFixed(2);
            item.unitPrice = basePrice * 100;
            item.total = item?.unitPrice * item?.qty;
        }
        values.status = status;

        mutate(
            {
                resource: 'v1/invoices',
                values: values,
                successNotification: { message: 'Successfully created', type: 'success' },
            },
            {
                onError: (_) => {
                    setIsLoading(false);
                },
                onSuccess: (_) => {
                    goBack();
                },
            },
        );
    };

    const submitForm = (status: QuoteStatus) => {
        setStatus(status);
        form.submit();
    };

    const disablePaymentDate = (current: dayjs.Dayjs): boolean => {
        return current < dayjs().startOf('day');
    };

    const disableDueDate = (current: dayjs.Dayjs): boolean => {
        const paymentDate = form.getFieldValue('date');
        return current < (paymentDate ? dayjs(paymentDate).startOf('day') : dayjs().startOf('day'));
    };

    const selectServiceOtherOptions = {
        label: t('quotes.fields.other_service.label'),
        value: SERVICE_OTHER_OPTION_ID,
    };

    return (
        <>
            <section className="invoice-container">
                <div className="block-heading edit-heading">
                    <span>{t('quotes.heading.createInvoiceTitle')}</span>
                    <div className="header-actions">
                        <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>
                        <Button
                            type="primary"
                            disabled={status === QuoteStatus.Draft && isLoading}
                            loading={status === QuoteStatus.Pending && isLoading}
                            onClick={() => submitForm(QuoteStatus.Pending)}
                        >
                            {t('quotes.actions.create')}
                        </Button>
                    </div>
                </div>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={{ items: [{ qty: 1, margin: 0 }] }}
                    onFinish={onFinish}
                >
                    <div className="section  details-section">
                        <div className="section-header">
                            {t('quotes.heading.invoiceInformation')}
                        </div>
                        <Row gutter={20}>
                            <Col xs={24} md={8} lg={8}>
                                <Form.Item
                                    label={t('quotes.fields.contact.label')}
                                    name="contactId"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('quotes.fields.contact.required'),
                                        },
                                    ]}
                                >
                                    <SelectContact onChange={(val) => onChangeContact(val)} />
                                </Form.Item>
                                {orders && (
                                    <Form.Item
                                        label={t('quotes.fields.order.label')}
                                        name="orderId"
                                        rules={
                                            !isTenant
                                                ? [
                                                      {
                                                          required: true,
                                                          message: t(
                                                              'quotes.fields.order.required',
                                                          ),
                                                      },
                                                  ]
                                                : []
                                        }
                                    >
                                        <Select
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                            allowClear
                                            onChange={(val) => onChangeOrder(val)}
                                        >
                                            {orders.map((order) => {
                                                return (
                                                    <Select.Option key={order.id} value={order.id}>
                                                        {order.incrementId}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Col>
                            <Col md={0} lg={2}></Col>
                            <Col xs={24} md={8} lg={7}>
                                <Form.Item
                                    label={t('quotes.fields.paymentDate.label')}
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('quotes.fields.paymentDate.required'),
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        getPopupContainer={(trigger) =>
                                            trigger?.parentNode as HTMLElement
                                        }
                                        format={DATE_FORMAT}
                                        disabledDate={(current) => disablePaymentDate(current)}
                                        onChange={() => form.resetFields(['dueDate'])}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8} lg={7}>
                                <Form.Item
                                    label={t('quotes.fields.paymentDueDate.label')}
                                    name="dueDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('quotes.fields.paymentDueDate.required'),
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        getPopupContainer={(trigger) =>
                                            trigger?.parentNode as HTMLElement
                                        }
                                        format={DATE_FORMAT}
                                        disabledDate={(current) => disableDueDate(current)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="section">
                        <Row gutter={20}>
                            <Col md={24}>
                                <Form.Item
                                    label={t('quotes.fields.description.label')}
                                    name="description"
                                >
                                    <TextEditor height="300px" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="section">
                        <div className="section-header">{t('quotes.heading.itemDetails')}</div>
                        <Row className="item-list">
                            <Col span={24}>
                                <Row
                                    className={` ${
                                        window.screen.width < 578 ? 'table-header-none' : ''
                                    } table-header`}
                                >
                                    <Col span={6}>{t('quotes.fields.item.label')}</Col>
                                    <Col span={6}>{t('quotes.fields.unitPrice.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.qty.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.frequency.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.total.label')}</Col>
                                </Row>
                                <Form.List name="items">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(
                                                ({ key, name, ...restField }, index: number) => {
                                                    const formItem =
                                                        form.getFieldValue('items')[index];
                                                    const type =
                                                        services[formItem?.serviceId]?.type || '';

                                                    let total =
                                                        formItem?.unitPrice * formItem?.qty || 0;

                                                    const isSelectedOtherService =
                                                        formItem?.serviceId ===
                                                        selectServiceOtherOptions.value;
                                                    if (!isNaN(+formItem?.margin)) {
                                                        total += (total * formItem.margin) / 100;
                                                    } else formItem.margin = 0;
                                                    if (orderId !== null) {
                                                        return (
                                                            <Row
                                                                className="list-product-item pb-3 item-custom"
                                                                key={key}
                                                            >
                                                                <Col xs={24} sm={8}>
                                                                    <span className="mb-3 block">
                                                                        {formItem?.productName}
                                                                    </span>
                                                                </Col>
                                                                <Col xs={24} sm={4}>
                                                                    <div className="unit-charge">
                                                                        <span>
                                                                            {formatPriceBaseOnCurrency(
                                                                                formItem?.unitPrice,
                                                                                CurrencyType.Vnd,
                                                                            )}
                                                                        </span>
                                                                        {type ===
                                                                            UserTypeEnum.WHITELABEL &&
                                                                        Number.parseInt(_charge) >
                                                                            0 ? (
                                                                            <span className="charge">
                                                                                <b>
                                                                                    {t(
                                                                                        'quotes.fields.service_fee.label',
                                                                                    )}
                                                                                    :{' '}
                                                                                </b>
                                                                                {`${Number.parseInt(
                                                                                    _charge,
                                                                                )}%`}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={4}>
                                                                    <span>{formItem?.qty}</span>
                                                                </Col>
                                                                <Col xs={24} sm={4}>
                                                                    <span>
                                                                        {t(
                                                                            'quotes.frequency.' +
                                                                                formItem.frequency,
                                                                        )}
                                                                    </span>
                                                                </Col>
                                                                <Col xs={24} sm={4}>
                                                                    <span>
                                                                        {formatPriceBaseOnCurrency(
                                                                            total,
                                                                            CurrencyType.Vnd,
                                                                        )}
                                                                    </span>
                                                                </Col>
                                                                {type === UserTypeEnum.TENANT ? (
                                                                    <Row>
                                                                        <Col xs={24} sm={24}>
                                                                            <strong className="block mr-2 mt-2 pb-2">
                                                                                {t(
                                                                                    'quotes.fields.margin.label',
                                                                                )}
                                                                                <span className="text-red-500">
                                                                                    *
                                                                                </span>
                                                                            </strong>
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                label={t(
                                                                                    'quotes.fields.margin.label',
                                                                                )}
                                                                                name={[
                                                                                    name,
                                                                                    'margin',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        validator: (
                                                                                            _,
                                                                                            value,
                                                                                        ) => {
                                                                                            if (
                                                                                                maxMargin &&
                                                                                                value >
                                                                                                    +maxMargin
                                                                                            ) {
                                                                                                return Promise.reject(
                                                                                                    t(
                                                                                                        'common.max_margin_invalid',
                                                                                                        {
                                                                                                            ns: 'common',
                                                                                                            maxMargin:
                                                                                                                maxMargin,
                                                                                                        },
                                                                                                    ),
                                                                                                );
                                                                                            }
                                                                                            return Promise.resolve();
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        required:
                                                                                            true,
                                                                                        message: t(
                                                                                            'quotes.fields.margin.required',
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <InputNumber
                                                                                    defaultValue={0}
                                                                                    value={0}
                                                                                    min={0}
                                                                                    precision={0}
                                                                                    onChange={
                                                                                        onChangeMargin
                                                                                    }
                                                                                    placeholder={t(
                                                                                        'quotes.fields.margin.placeholder',
                                                                                    )}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                ) : null}
                                                            </Row>
                                                        );
                                                    }

                                                    return (
                                                        <Row
                                                            className="list-product-item item-custom"
                                                            key={key}
                                                        >
                                                            <Col xs={24} sm={6}>
                                                                {isTenant && (
                                                                    <>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[
                                                                                name,
                                                                                'serviceId',
                                                                            ]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: t(
                                                                                        'quotes.fields.item.required',
                                                                                    ),
                                                                                },
                                                                            ]}
                                                                            label={t(
                                                                                'quotes.fields.item.label',
                                                                            )}
                                                                        >
                                                                            <Select
                                                                                getPopupContainer={(
                                                                                    trigger,
                                                                                ) =>
                                                                                    trigger.parentNode
                                                                                }
                                                                                options={
                                                                                    isTenant
                                                                                        ? [
                                                                                              ...(serviceSelectProps.options?.filter(
                                                                                                  (
                                                                                                      item,
                                                                                                  ) =>
                                                                                                      item.value &&
                                                                                                      services[
                                                                                                          item
                                                                                                              .value
                                                                                                      ]
                                                                                                          ?.type ===
                                                                                                          UserTypeEnum.TENANT,
                                                                                              ) ||
                                                                                                  []),
                                                                                              selectServiceOtherOptions,
                                                                                          ]
                                                                                        : [
                                                                                              ...(serviceSelectProps.options ||
                                                                                                  []),
                                                                                          ]
                                                                                }
                                                                                showSearch={false}
                                                                                onChange={(
                                                                                    item: any,
                                                                                ) =>
                                                                                    onChangeService(
                                                                                        item,
                                                                                        index,
                                                                                    )
                                                                                }
                                                                                placeholder={
                                                                                    'Select service'
                                                                                }
                                                                            />
                                                                        </Form.Item>

                                                                        {formItem?.serviceId &&
                                                                            isSelectedOtherService && (
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    label={t(
                                                                                        'quotes.fields.product.label',
                                                                                    )}
                                                                                    name={[
                                                                                        name,
                                                                                        'productName',
                                                                                    ]}
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                t(
                                                                                                    'quotes.fields.product.required',
                                                                                                ),
                                                                                        },
                                                                                    ]}
                                                                                    className="product-custom "
                                                                                >
                                                                                    <Input
                                                                                        onChange={() => {
                                                                                            form.validateFields(
                                                                                                [
                                                                                                    [
                                                                                                        'items',
                                                                                                        index,
                                                                                                        'unitPrice',
                                                                                                    ],
                                                                                                    [
                                                                                                        'items',
                                                                                                        index,
                                                                                                        'frequency',
                                                                                                    ],
                                                                                                ],
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    {type ===
                                                                                        UserTypeEnum.WHITELABEL &&
                                                                                    Number.parseInt(
                                                                                        _charge,
                                                                                    ) > 0 ? (
                                                                                        <span className="charge">
                                                                                            <b>
                                                                                                {t(
                                                                                                    'quotes.fields.service_fee.label',
                                                                                                )}
                                                                                                :{' '}
                                                                                            </b>
                                                                                            {`${Number.parseInt(
                                                                                                _charge,
                                                                                            )}%`}
                                                                                        </span>
                                                                                    ) : null}
                                                                                </Form.Item>
                                                                            )}

                                                                        {formItem?.serviceId &&
                                                                            !isSelectedOtherService && (
                                                                                <Form.Item
                                                                                    {...restField}
                                                                                    name={[
                                                                                        name,
                                                                                        'productId',
                                                                                    ]}
                                                                                    label={t(
                                                                                        'quotes.fields.product.label',
                                                                                    )}
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                t(
                                                                                                    'quotes.fields.product.required',
                                                                                                ),
                                                                                        },
                                                                                    ]}
                                                                                    className="product-custom"
                                                                                >
                                                                                    <Select
                                                                                        getPopupContainer={(
                                                                                            trigger,
                                                                                        ) =>
                                                                                            trigger.parentNode
                                                                                        }
                                                                                        showSearch={
                                                                                            false
                                                                                        }
                                                                                        placeholder={t(
                                                                                            'quotes.fields.product.placeholder',
                                                                                        )}
                                                                                        onChange={(
                                                                                            item,
                                                                                        ) =>
                                                                                            onChangeProduct(
                                                                                                item,
                                                                                                index,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {services[
                                                                                            formItem?.serviceId
                                                                                        ]?.products.map(
                                                                                            (
                                                                                                product: any,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <Select.Option
                                                                                                        key={
                                                                                                            product.id
                                                                                                        }
                                                                                                        value={
                                                                                                            product.id
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            product.name
                                                                                                        }
                                                                                                    </Select.Option>
                                                                                                );
                                                                                            },
                                                                                        )}
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            )}
                                                                    </>
                                                                )}
                                                            </Col>
                                                            <Col xs={24} sm={6}>
                                                                <>
                                                                    {isTenant &&
                                                                    isSelectedOtherService ? (
                                                                        <>
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[
                                                                                    name,
                                                                                    'unitPrice',
                                                                                ]}
                                                                                rules={[
                                                                                    {
                                                                                        required:
                                                                                            true,
                                                                                        message: t(
                                                                                            'quotes.fields.unitPrice.required',
                                                                                        ),
                                                                                    },
                                                                                ]}
                                                                                label={t(
                                                                                    'quotes.fields.unitPrice.label',
                                                                                )}
                                                                            >
                                                                                <InputNumber
                                                                                    precision={2}
                                                                                    formatter={(
                                                                                        value,
                                                                                    ) =>
                                                                                        `${value}`.replace(
                                                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                                                            ',',
                                                                                        )
                                                                                    }
                                                                                    parser={(
                                                                                        value,
                                                                                    ) =>
                                                                                        value!.replace(
                                                                                            /\$\s?|(,*)/g,
                                                                                            '',
                                                                                        )
                                                                                    }
                                                                                    onChange={
                                                                                        onChangeStatus
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        </>
                                                                    ) : (
                                                                        <span className="block mt-3 mb-8 unit-price-custom invoice-price-custom">
                                                                            <span className="custom-label">
                                                                                {t(
                                                                                    'quotes.fields.unitPrice.label',
                                                                                )}
                                                                            </span>
                                                                            {formatPrice(
                                                                                formItem?.unitPrice *
                                                                                    1,
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                    {type ===
                                                                        UserTypeEnum.WHITELABEL &&
                                                                    Number.parseInt(_charge) > 0 ? (
                                                                        <span className="charge">
                                                                            <b>
                                                                                {t(
                                                                                    'quotes.fields.service_fee.label',
                                                                                )}
                                                                                :{' '}
                                                                            </b>
                                                                            {`${Number.parseInt(
                                                                                _charge,
                                                                            )}%`}
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                                {type === UserTypeEnum.TENANT &&
                                                                formItem?.serviceId ? (
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label={t(
                                                                            'quotes.fields.margin.label',
                                                                        )}
                                                                        name={[name, 'margin']}
                                                                        rules={[
                                                                            {
                                                                                validator: (
                                                                                    _,
                                                                                    value,
                                                                                ) => {
                                                                                    if (
                                                                                        maxMargin &&
                                                                                        value >
                                                                                            +maxMargin
                                                                                    ) {
                                                                                        return Promise.reject(
                                                                                            t(
                                                                                                'common.max_margin_invalid',
                                                                                                {
                                                                                                    ns: 'common',
                                                                                                    maxMargin:
                                                                                                        maxMargin,
                                                                                                },
                                                                                            ),
                                                                                        );
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                },
                                                                            },
                                                                            {
                                                                                required: true,
                                                                                message: t(
                                                                                    'quotes.fields.margin.required',
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        className="product-custom custom-margin"
                                                                    >
                                                                        <InputNumber
                                                                            defaultValue={0}
                                                                            min={0}
                                                                            precision={0}
                                                                            onChange={
                                                                                onChangeStatus
                                                                            }
                                                                            placeholder={t(
                                                                                'quotes.fields.margin.placeholder',
                                                                            )}
                                                                        />
                                                                    </Form.Item>
                                                                ) : null}
                                                            </Col>

                                                            <Col xs={24} sm={4}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label={t(
                                                                        'quotes.fields.qty.label',
                                                                    )}
                                                                    name={[name, 'qty']}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t(
                                                                                'quotes.fields.qty.required',
                                                                            ),
                                                                        },
                                                                        {
                                                                            min: 1,
                                                                            type: 'number',
                                                                            message:
                                                                                'Quantity cannot be less than 1',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <InputNumber
                                                                        min={1}
                                                                        defaultValue={1}
                                                                        step={1}
                                                                        precision={0}
                                                                        onChange={onChangeStatus}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={4}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'frequency']}
                                                                    label={t(
                                                                        'quotes.fields.frequency.label',
                                                                    )}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t(
                                                                                'quotes.fields.frequency.required',
                                                                            ),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        getPopupContainer={(
                                                                            trigger,
                                                                        ) => trigger.parentNode}
                                                                    >
                                                                        <Select.Option
                                                                            value={
                                                                                ProductFrequencyEnum.ONE_TIME_PAYMENT
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            {t(
                                                                                'services.frequency.' +
                                                                                    ProductFrequencyEnum.ONE_TIME_PAYMENT,
                                                                                { ns: 'common' },
                                                                            )}
                                                                        </Select.Option>
                                                                        <Select.Option
                                                                            value={
                                                                                ProductFrequencyEnum.PER_MONTH
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            {t(
                                                                                'services.frequency.' +
                                                                                    ProductFrequencyEnum.PER_MONTH,
                                                                                { ns: 'common' },
                                                                            )}
                                                                        </Select.Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col
                                                                xs={24}
                                                                sm={4}
                                                                className="total-wrapper "
                                                            >
                                                                <span className="total-price">
                                                                    {t('quotes.fields.total.label')}
                                                                </span>
                                                                <div className="total-container">
                                                                    <span>
                                                                        {formatPriceBaseOnCurrency(
                                                                            total,
                                                                            CurrencyType.Vnd,
                                                                        )}
                                                                    </span>
                                                                    {index > 0 && (
                                                                        <img
                                                                            className="ml-auto"
                                                                            src="/images/icons/remove.svg"
                                                                            onClick={() => {
                                                                                remove(name);
                                                                                onChangeStatus();
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    );
                                                },
                                            )}
                                            {isTenant && !orderId && (
                                                <div className="btn-add-container">
                                                    <Button
                                                        onClick={() => add({ qty: 1 })}
                                                        type="link"
                                                        icon={<PlusOutlined />}
                                                    >
                                                        {t('quotes.actions.addItem')}
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Form.List>
                            </Col>
                        </Row>
                        <div className="sum-total flex">
                            <span>{t('quotes.heading.totalPrice')}</span>
                            <span className="ml-auto">
                                {formatPriceBaseOnCurrency(totalOrder, CurrencyType.Vnd)}
                            </span>
                        </div>
                    </div>
                    <div className="section">
                        <div className="section-header">{t('quotes.fields.tc.label')}</div>
                        <Row gutter={20}>
                            <Col md={24}>
                                <Form.Item name="termsAndConditions">
                                    <TextEditor />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </section>
        </>
    );
};
